<h2 mat-dialog-title>Tag</h2>
<mat-dialog-content class="mat-typography">
    <form class="w-100-p" [formGroup]="postForm" novalidate fxLayout="column" fxLayoutAlign="start">
        <div fxLayout="row">
            <mat-form-field appearance="outline">
                <input matInput  formControlName="title">
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="SendReply()" *ngIf="data">Update</button>
    <button mat-button (click)="SendReply()" *ngIf="!data">Add</button>
</mat-dialog-actions>