<div *ngIf="isVisible" id="splash-screen">
    <div class="logo">
        <img *ngIf="siteName === 'Newbiefans'" src="assets/images/logos/logo_newbiefans.png">
        <img *ngIf="siteName !== 'Newbiefans'" src="assets/images/logos/poster.png">
    </div>

    <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
    </div>
</div>