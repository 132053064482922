<div class="sidebar-content">

    <div class="card">

        <!-- SIDEBAR HEADER -->
<!--        <div class="header p-24" fxLayout="row" fxLayoutAlign="start center">-->

            <!-- USER -->
<!--            <img [src]="user.avatar" class="avatar mr-16" [alt]="user.name"/>-->
<!--            <span class="h5">{{user.name}}</span>-->
            <!-- / USER -->
<!--        </div>-->
        <!-- / SIDEBAR HEADER -->

        <!-- SIDEBAR CONTENT -->
        <div class="content py-16" fusePerfectScrollbar>

            <div class="nav material2">

                <div class="nav-item" aria-label="frequently contacted" (click)="changeFilter('active')">
                    <a class="nav-link" matRipple [ngClass]="{'active accent':filterBy ==='active'}">
                        <div class="title">Active contacts</div>
                    </a>
                </div>

                <div class="nav-item" aria-label="starred" (click)="changeFilter('deleted')">
                    <a class="nav-link" matRipple [ngClass]="{'active accent':filterBy ==='deleted'}">
                        <div class="title">Deleted Contacts</div>
                    </a>
                </div>
            </div>

        </div>

    </div>

</div>
<!-- / SIDEBAR CONTENT -->
