import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import {environment} from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    uri = environment.apiURL + 'settings/';
    constructor(private http: HttpClient) { }

    insertSettings(param = {}): Observable<any> {
        return this.http.post(this.uri + 'insertSetting', param);
    }

    getSettingsData(): Observable<any> {
        return this.http.get(this.uri + '/getSettings');
    }
}
