import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SyslogService {

    uri = environment.apiURL + 'logs/';
    onPostChanged: BehaviorSubject<any>;
    logs = [];

    constructor(private http: HttpClient) {
    }

    getLogAll(param = {}): Observable<any> {
        return this.http.post(this.uri + 'getLog', param);
    }

    getLoginLog(param = {}): Observable<any> {
        return this.http.post(this.uri + 'loginlog', param);
    }
}
