<div id="login" fxLayout="column" fusePerfectScrollbar>

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" *fuseIfOnDom [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img *ngIf="siteName === 'Newbiefans'" src="assets/images/logos/logo_newbiefans.png">
                <img *ngIf="siteName !== 'Newbiefans'" src="assets/images/logos/poster.png">
            </div>

            <!-- <div class="title">Sign up to see photos and videos from your friends</div> -->

            <form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="onSubmit()" fxLayout="column" >

                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput placeholder="Username" formControlName="loginId">
                    <mat-error *ngIf="loginFormErrors.loginId.required">
                        Username is required!
                    </mat-error>
                    <!-- <mat-error *ngIf="!loginFormErrors.email.required && loginFormErrors.email.email">
                        Please enter a valid email address
                    </mat-error> -->
                </mat-form-field>

                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input matInput placeholder="Password" type="password" formControlName="password">
                    <mat-icon matSuffix class="secondary-text">visibility_off</mat-icon>
                    <mat-error *ngIf="loginFormErrors.password.required">
                        Password is required!
                    </mat-error>
                </mat-form-field>

                <mat-error *ngIf="errorOccurred">
                    {{error}}
                </mat-error>

                <button mat-raised-button color="accent" class="submit-button w-30-p" aria-label="Log In" [disabled]="loginForm.invalid">
                    Log In
                </button>

            </form>
        </div>
    </div>
</div>