import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {fuseAnimations} from '@fuse/animations';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ReportService} from '../../../../shared/services/report/report.service';
import {SocketService} from 'app/shared/services/socket.service';
import {BlockUI, NgBlockUI} from 'ng-block-ui';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-report-dialog',
    templateUrl: './report-dialog.component.html',
    styleUrls: ['./report-dialog.component.scss'],
    animations: fuseAnimations,
})
export class ReportDialogComponent implements OnInit, OnDestroy {
    comments = [];
    commentTotal = 0;
    private _unsubscribeAll: Subject<any>;

    @BlockUI() blockUI: NgBlockUI;
    postForm: UntypedFormGroup;
    loading = false;
    submitted = false;
    @ViewChild('imgPhoto') imgPhoto;
    @ViewChild('imgVideo') imgVideo;

    constructor(
        private reportService: ReportService,
        private formBuilder: UntypedFormBuilder,
        public dialogRef: MatDialogRef<ReportDialogComponent>,
        private socketService: SocketService,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.postForm = this.formBuilder.group({
            title: ['']
        });

        if (this.data && !this.data.report.isFlagged) {
            this.reportService.updateFlag({id: this.data.report._id}).subscribe((param) => {
                if (param && param['success'] === 1) {
                    this.data.report.isFlagged = true;
                }
            });
        }
        this.loadMoreComments(this.data?.report?._id?.toString());
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    SendReply(): void {
        if (!this.f.title.value) {
            return;
        }
        this.submitted = true;
        this.loading = true;

        this.blockUI.start('Sharing...');

        const params = {
            description: this.f.title.value,
            id: this.data?.report._id
            // location: this.f.country.value,
        };

        this.reportService.createReply(params).subscribe((_data) => {
            this.submitted = false;
            this.loading = false;
            this.blockUI.stop();
            this.data.report.description = params.description;
            this.socketService.emitData('sendNotificationReport', _data['data'].reportParam);
            this.dialogRef.close();
        });
    }

    get f() {
        return this.postForm.controls;
    }

    protected loadMoreComments(reportId) {
        this.reportService.getComment({
            postId: reportId,
            loaded: this.comments.length
        }).pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
            if (data.success === 1) {
                // tslint:disable-next-line:prefer-for-of
                for (let index = 0; index < data.data.comments.length; index++) {
                    let reportItem: any = {};
                    if (data.data.comments[index].owner) {
                        reportItem.commenter = data.data.comments[index].owner;
                    }
                    reportItem.text = data.data.comments[index].description;
                    reportItem.isAdmin = data.data.comments[index].isAdmin;
                    reportItem.report = true;
                    reportItem.createdAt = data.data.comments[index].createdAt;
                    this.comments.push(reportItem);
                }
                if (data.data.commentTotal) {
                    this.commentTotal = data.data.commentTotal;
                }
            }
        }, (err) => {
        });
    }

    onSubmit() {
        if (!this.f.title.value) {
            return;
        }
        this.blockUI.start('Reporting...');
        const params = {
            reportId: this.data?.report?._id,
            title: this.f.title.value,
            userId: this.data?.report?.owner._id.toString()
            // location: this.f.country.value,
        };
        this.reportService.createComment(params).subscribe(data => {
            this.blockUI.stop();
            if (data['success'] === 1) {
                this.comments.unshift({
                    commenter: this.data.user,
                    text: this.f.title.value,
                    isAdmin: true,
                    report: true,
                    createdAt: new Date()
                });
                this.postForm.reset();
            } else if (data['error']) {
                alert(data['error']);
            }
        }, err => {
            this.blockUI.stop();
        });
    }
}
