import { FuseUtils } from '@fuse/utils';

export class Contact
{
    id: string;
    name: string;
    avatar: string;
    email: string;
    contributor: boolean;
    isDeleted: boolean;
    createdAt: string;
    lastLogin: string;
    isFlagged: boolean;
    loginCount: number;
    joinMethod: {
        firstM: string,
        lastM: string
    };

    /**
     * Constructor
     *
     * @param contact
     */
    constructor(contact)
    {
        {
            this.id = contact._id || FuseUtils.generateGUID();
            this.name = contact.username || '';
            this.avatar = contact.avatar || 'assets/images/avatars/profile.jpg';
            this.email = contact.email || '';
            this.contributor = contact.contributor ? true : false;
            this.isDeleted = contact.isDeleted ? true : false;
            this.createdAt = contact.createdAt;
            this.lastLogin = contact.lastLogin ?? '';
            this.isFlagged = contact.isFlagged ? true : false;
            this.loginCount = contact.loginCount ?? 0;
            this.joinMethod = {
              firstM: contact.joinMethod.firstM,
              lastM: contact.joinMethod.lastM
            };
        }
    }
}
