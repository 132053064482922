
<div id="ngx-datatable" class="page-layout simple fullwidth docs">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div class="h2 mt-16">Login Log</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content_sys p-24" fusePerfectScrollbar>
        <ngx-datatable
            class="material"
            [selected]="selected"
            (select)='onSelect($event)'
            (activate)="onActivate($event)"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [headerHeight]="40"
            [footerHeight]="56"
            [rowHeight]="50"
            [reorderable]="reorderable"
            [selectionType]="'checkbox'"
            [columnMode]="'force'"
            [externalPaging]="true"
            [count]="page.count"
            [offset]="page.offset"
            [limit]="page.limit"
            (page)="pageCallback($event)"
            (sort)="onSort($event)"
        >
            <ngx-datatable-column name="Username" prop="username" [width]="50">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Email" prop="email" [width]="70" sortName="email">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span [matTooltip]="value" matTooltipPosition="below">{{value}}</span>
                </ng-template>
            </ngx-datatable-column>
<!--            <ngx-datatable-column name="Login Count" prop="loginCount" [width]="50" sortName="logcount">-->
<!--                <ng-template let-value="value" ngx-datatable-cell-template>-->
<!--                    <span>{{value}}</span>-->
<!--                </ng-template>-->
<!--            </ngx-datatable-column>-->
            <ngx-datatable-column name="LAST LOGIN DATE" prop="createdAt" [width]="50" sortName="date">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value | date:'mediumDate'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Login Count" prop="loginCount" [width]="50" sortName="loginCount">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value ? value: 0}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="TIME" prop="createdAt" [width]="50" sortName="time">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value | date:'shortTime'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="How long ago" prop="createdAt" [width]="50">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{formatPeriod(value)}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="IP ADDRESS" prop="ip" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="COUNTRY" prop="country" [width]="50"></ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
