import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CommentService } from "@shared/services/comment/comment.service";
import { Router } from "@angular/router";
import * as moment from "moment";
import { Comment } from "@shared/models/comment.model"


interface CommentResponse {
  data: {
    comments: Comment[];
    pagination: {
      total: number;
      page: number;
      limit: number;
      offset: number;
      totalPages: number;
    };
  };
  error: any;
  success: any;
}

interface Response {
  error: any,
  success: any
}

@Component({
  selector: 'app-admin-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminCommentsComponent {
  @Input() comments: any[];
  @Input() user: any;
  @Input() dialogClose: boolean;
  loadingIndicator: boolean = true;
  reorderable: boolean;
  page = {
    limit: 20,
    count: 0,
    offset: 0,
    currentPage: 1,
    totalPages: 0
  };

  constructor(
    private router: Router,
    private commentService: CommentService
  ) {

  }

  ngOnInit(): void {
    this.handleGetComments({ page: 1, limit: 20 });
  }

  handleGetComments(query?: { page: number; limit: number }) {
    // Call the service with the computed offset and limit
    this.commentService.getAll(query).subscribe((response: CommentResponse) => {
      if (response?.success) {
        // Update pagination data based on API response
        this.page.count = response.data.pagination.total;
        this.page.limit = response.data.pagination.limit;
        this.page.currentPage = response.data.pagination.page - 1;
        this.page.totalPages = response.data.pagination.totalPages;
        this.comments = response.data.comments;
      }
    });
  }

  pageCallback(event: any): void {
    const currentPage = event.offset + 1;
    this.handleGetComments({
      page: currentPage,
      limit: this.page.limit
    });
  }

  hideComment(row:Comment) {
    const payload = {
      hide: !row.hide
    };
     this.commentService.hideComment(row._id, payload).subscribe((response: Response) => {
      if (response.success) {
        row.hide = !row.hide;
        this.handleGetComments({
          page: this.page.currentPage + 1,
          limit: this.page.limit
        });
      }
     })
  }

  toggleUserComment(row:Comment) {
    const payload = {
      hide_user_comment: !row.hide_user_comment
    };
     this.commentService.toggleHideUserComment(row.commenter._id, payload).subscribe((response: Response) => {
      if (response.success) {
        row.hide_user_comment = !row.hide_user_comment;
        this.handleGetComments({
          page: this.page.currentPage + 1,
          limit: this.page.limit
        });
      }
     })
  }

  protected viewCommentDetail(comment) {
    comment.isdetail = true;
  }

  protected highlight(text) {
    return text ? text.replace(/(^|\s)(#[a-z\d-]+)/ig, '$1<a class=\'hash-tag\'>$2</a>') : '';
  }


  gotoUserPage(username) {
    if (this.dialogClose) {
      location.href = '/' + username;
    } else {
      this.router.navigate(['/' + username]);
    }
  }

  handleMissingImage(event: ErrorEvent) {
    (event.target as HTMLImageElement).src = 'https://images-test-cdn.newbienudes.com/m/media-nn-test/pp/nopic.jpg';
  }
  // tslint:disable-next-line:typedef
  formatPeriod(value) {
    let str: string = moment(value).fromNow();
    if (str.length > 3 && str.substr(str.length - 4) === 'ago') {
      str = str.substr(0, str.length - 4);
    }
    return str;
  }
}
