// modal-content.component.ts
import { Component, Inject } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-modal-content',
  templateUrl: './content-html.component.html',
  styleUrls: ['./content-html.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ContentHtmlComponent {
  
  safeHtmlContent: SafeHtml;
  content: any;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer) {
    this.content = this.data.content;
    this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.content);
  }
}
