import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BehaviorSubject, Observable, of, Subject } from "rxjs";
import { ContactsService } from "../../../../shared/services/contacts/contacts.service";
import { MatDialog } from "@angular/material/dialog";
import { AppConstants } from "../../../../shared/constants";
import { MatLegacySlideToggleChange as MatSlideToggleChange } from "@angular/material/legacy-slide-toggle";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { catchError, finalize, takeUntil } from "rxjs/operators";
import { PopupPostComponent } from "../popup-post/popup-post.component";
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { PostService } from "./post.service";
import { FuseProgressBarService } from "../../../../../@fuse/components/progress-bar/progress-bar.service";

@Component({
    selector: "app-posts",
    templateUrl: "./posts.component.html",
    styleUrls: ["./posts.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PostsComponent implements OnInit {
    // tslint:disable-next-line:variable-name
    param_selected = [];
    selected = [];
    rows: any[];
    loadingIndicator: boolean;
    reorderable: boolean;
    user: any = null;
    isDeleted = false;
    tags = [];
    page = {
        limit: 15,
        count: 0,
        offset: 0,
        isDeleted: false,
        sort: {},
    };
    dataSource: FilesDataSource | null;
    private _unsubscribeAll: Subject<any>;
    @BlockUI() blockUI: NgBlockUI;

    constructor(
        private _contactService: ContactsService,
        private _matDialog: MatDialog,
        private matSnackBar: MatSnackBar,
        private postService: PostService,
        private fuseProgressBarService: FuseProgressBarService
    ) {
        this._unsubscribeAll = new Subject();
        this.user = JSON.parse(localStorage.getItem(AppConstants.currentUser));
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.postService);
        this.dataSource.loadPostData(15, 0, 0, false, {});
        this._contactService.onMsg
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                // tslint:disable-next-line:triple-equals
                if (data && data.type == "delete") {
                    this.rows = this.rows.filter((item) => {
                        if (item.id !== data.id) {
                            return item;
                        }
                    });
                }
            });
        this._contactService.getTagList().subscribe(
            (data) => {
                if (data) {
                    for (const d of data) {
                        this.tags.push(d.content);
                    }
                }
            },
            (err) => {
                console.log(err);
            }
        );
        this.dataSource.postSubject.pipe().subscribe((response) => {
            if (response != null && response["success"]) {
                this.rows = response.rows;
                this.page.count = response.pageCount;
            }
        });

        this.dataSource.loadingSubject.pipe().subscribe((loadParam) => {
            if (loadParam) {
                this.fuseProgressBarService.show();
            } else {
                this.fuseProgressBarService.hide();
            }
        });
    }

    loadPostData(
        limit: number,
        count: number,
        offset: number,
        isDeleted: boolean,
        sort: any,
        postService: any,
        cd: any
    ) {
        this.dataSource = new FilesDataSource(postService);
        this.dataSource.loadPostData(limit, count, offset, isDeleted, sort);
        this.dataSource.postSubject.pipe().subscribe((response) => {
            if (response != null && response["success"]) {
                this.rows = response.rows;
                cd.detectChanges();
                this.page.count = response.pageCount;
            }
        });
    }

    onActivate(event): void {}

    onSelect({ selected }): void {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    clear(type = ""): void {
        this.param_selected = [];
        let ids = "all";
        if (type !== "") {
        }

        if ((type === "" && this.selected.length > 0) || type !== "") {
            this.selected.forEach((value) => {
                this.param_selected.push(value.id);
            });
            if (this.param_selected.length > 0) {
                ids = this.param_selected.join();
            }
            this._contactService.deletePosts({ ids: ids }).subscribe((data) => {
                if (ids === "all") {
                    this.rows = [];
                } else {
                    this.rows = this.rows.filter((item) => {
                        if (!ids.includes(item.id)) {
                            return item;
                        }
                    });
                }
            });
        }
    }

    changeContributor($event: MatSlideToggleChange, uid = "") {
        this.blockUI.start("Loading...");
        this._contactService.updateContributor($event.checked, uid).subscribe(
            (data) => {
                if (data["success"] === 1) {
                    this.matSnackBar.open("Successfully processed", "", {
                        verticalPosition: "top",
                        horizontalPosition: "right",
                        politeness: "assertive",
                        duration: 1500,
                    });
                    this.rows = this.rows.filter((item) => {
                        if (item._id === uid) {
                            item.contributor = $event.checked;
                        }
                        return item;
                    });
                } else {
                    this.matSnackBar.open(data["error"], "", {
                        verticalPosition: "top",
                        horizontalPosition: "right",
                        politeness: "assertive",
                        duration: 1500,
                    });
                    $event.source.checked = !$event.checked;
                }
                this.blockUI.stop();
            },
            () => {
                this.matSnackBar.open("Unexpected Error", "", {
                    verticalPosition: "top",
                    horizontalPosition: "right",
                    politeness: "assertive",
                    duration: 1500,
                });
                this.blockUI.stop();
                $event.source.checked = !$event.checked;
            }
        );
    }

    gotoPost(value): void {
        const dialogRef = this._matDialog.open(PopupPostComponent, {
            width: "600px",
            data: {
                post: value,
                allTags: this.tags,
            },
        });
    }

    pageCallback(pageInfo: {
        count?: number;
        pageSize?: number;
        limit?: number;
        offset?: number;
    }): void {
        this.page.offset = pageInfo.offset;
        this.reloadTable();
    }

    reloadTable(): void {
        this.dataSource.loadPostData(
            this.page.limit,
            this.page.count,
            this.page.offset,
            this.page.isDeleted,
            this.page.sort
        );
    }

    onSort(event): void {
        const sort = event.sorts[0];
        if (sort) {
            this.page.sort = sort;
            this.dataSource.loadPostData(
                15,
                0,
                0,
                this.page.isDeleted,
                this.page.sort
            );
        }
    }
}

export class FilesDataSource implements DataSource<any> {
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public postSubject = new BehaviorSubject<any>(null);

    constructor(private postService: PostService) {}

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(collectionViewer: CollectionViewer): Observable<any> {
        return this.postSubject.asObservable();
    }

    /**
     * Disconnect
     */
    disconnect(collectionViewer: CollectionViewer): void {
        this.postSubject.complete();
        this.loadingSubject.complete();
    }

    // tslint:disable-next-line:typedef
    loadPostData(
        limit: number,
        count: number,
        offset: number,
        isDeleted: boolean,
        sort: any
    ) {
        this.loadingSubject.next(true);
        this.postService
            .getPostAll({
                limit: limit,
                count: count,
                offset: offset,
                isDeleted: isDeleted,
                sort: sort,
            })
            .pipe(
                catchError(() => of()),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe((response) => {
                if (response["success"] && response["data"]) {
                    this.postSubject.next({
                        success: true,
                        rows: response["data"].postList,
                        pageCount: response["data"].postCount,
                    });
                } else {
                    this.postSubject.next({ sucess: false });
                }
            });
    }
}
