<div id="ngx-datatable" class="page-layout simple fullwidth docs">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div class="h2 mt-16">Payout</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="p-24">
        <ngx-datatable
            class="material"
            [selected]="selected"
            (select)='onSelect($event)'
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="48"
            [footerHeight]="56"
            [rowHeight]="'auto'"
            [scrollbarH]="true"
            [reorderable]="reorderable"
            [selectionType]="'checkbox'"
            [externalPaging]="true"
            [count]="page.count"
            [offset]="page.offset"
            [limit]="page.limit"
            (page)="pageCallback($event)"
        >
            <ngx-datatable-column name="Payment ID" prop="id"></ngx-datatable-column>
            <ngx-datatable-column name="Date" prop="updatedAt" sortable="true">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <span>{{ row.updatedAt | date:'yyyy-MM-dd hh:mm'  }}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PAYOUT" prop="fromCash"></ngx-datatable-column>
            <ngx-datatable-column name="FEE(%)">
                <ng-template ngx-datatable-cell-template let-row="row">
                    {{ !row.fromCash ? 0 : (100 - 100 * row.toCash / row.fromCash).toFixed(2) }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="FEE(Amount)">
                <ng-template ngx-datatable-cell-template let-row="row">
                    {{ !row.fromCash && !row.toCash ? 0 : (row.fromCash - row.toCash).toFixed(2) }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="SENT AMOUNT">
                <ng-template ngx-datatable-cell-template let-row="row">
                    {{ row.toCash ? row.toCash.toFixed(2) : 0 }}
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="PAYMENT METHOD" prop="payment"></ngx-datatable-column>
            <ngx-datatable-column name="TRANSACTION ID" prop="transactionID"></ngx-datatable-column>
            <ngx-datatable-column name="UserName" prop="owner.username"></ngx-datatable-column>
            <ngx-datatable-column name="State" prop="isFlagged">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span *ngIf="value === false">Pending</span>
                    <span *ngIf="value === true">Accepted</span>
                </ng-template>
            </ngx-datatable-column>
            <!--            <ngx-datatable-column name="CreatedAt" prop="createdAt">-->
            <!--                <ng-template let-value="value" ngx-datatable-cell-template>-->
            <!--                    <span>{{value | date:'medium'}}</span>-->
            <!--                </ng-template>-->
            <!--            </ngx-datatable-column>-->
            <ngx-datatable-column name="">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <button mat-mini-fab color="accent" (click)="gotoPayInform(row)">
                            <mat-icon *ngIf="!row.isFlagged">send</mat-icon>
                            <mat-icon *ngIf="row.isFlagged">check</mat-icon>
                        </button>
                        <!-- <div>
                            <button mat-mini-fab color="warn" (click)="deletePayInform()">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </div> -->
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>

