export enum AppConstants {
    currentUser = 'currentUser',
    searchParams = 'searchParams',
    chatRoom    =   'chatRoom',

    PROFILE_URL = '/profile',
    LOGIN_URL = '/login',
    
    KUDO_POSITION_ITEM              = 1,
    KUDO_POSITION_PROFILE           = 2,

    TRANSACTION_DOWNLOAD_ITEM       = 1,
    TRANSACTION_GIVE_TIP            = 2,
    TRANSACTION_TAP_SHARE_LINK      = 3,
    TEST = 1,
}

// tslint:disable-next-line:variable-name
export let Const_countries = [
        {'text': 'Andorra'                           , 'value': 'ad'},
        {'text': 'United Arab Emirates'              , 'value': 'ae'},
        {'text': 'Afghanistan'                       , 'value': 'af'},
        {'text': 'Antigua and Barbuda'               , 'value': 'ag'},
        {'text': 'Anguilla'                          , 'value': 'ai'},
        {'text': 'Albania'                           , 'value': 'al'},
        {'text': 'Armenia'                           , 'value': 'am'},
        {'text': 'Netherlands Antilles'              , 'value': 'an'},
        {'text': 'Angola'                            , 'value': 'ao'},
        {'text': 'Antarctica'                        , 'value': 'aq'},
        {'text': 'Argentina'                         , 'value': 'ar'},
        {'text': 'American Samoa'                    , 'value': 'as'},
        {'text': 'Austria'                           , 'value': 'at'},
        {'text': 'Australia'                         , 'value': 'au'},
        {'text': 'Aland Islands'                     , 'value': 'ax'},
        {'text': 'Aruba'                             , 'value': 'aw'},
        {'text': 'Azerbaidjan'                       , 'value': 'az'},
        {'text': 'Bosnia and Herzegovina'            , 'value': 'ba'},
        {'text': 'Barbados'                          , 'value': 'bb'},
        {'text': 'Bangladesh'                        , 'value': 'bd'},
        {'text': 'Belgium'                           , 'value': 'be'},
        {'text': 'Burkina Faso'                      , 'value': 'bf'},
        {'text': 'Bulgaria'                          , 'value': 'bg'},
        {'text': 'Bahrain'                           , 'value': 'bh'},
        {'text': 'Burundi'                           , 'value': 'bi'},
        {'text': 'Benin'                             , 'value': 'bj'},
        {'text': 'Saint Barthelemey'                 , 'value': 'bl'},
        {'text': 'Bermuda'                           , 'value': 'bm'},
        {'text': 'Brunei Darussalam'                 , 'value': 'bn'},
        {'text': 'Bolivia'                           , 'value': 'bo'},
        {'text': 'Brazil'                            , 'value': 'br'},
        {'text': 'Bahamas'                           , 'value': 'bs'},
        {'text': 'Bhutan'                            , 'value': 'bt'},
        {'text': 'Bouvet Island'                     , 'value': 'bv'},
        {'text': 'Botswana'                          , 'value': 'bw'},
        {'text': 'Belarus'                           , 'value': 'by'},
        {'text': 'Belize'                            , 'value': 'bz'},
        {'text': 'Canada'                            , 'value': 'ca'},
        {'text': 'Cocos Islands'                     , 'value': 'cc'},
        {'text': 'Congo, Democratic Rebpulic of the' , 'value': 'cd'},
        {'text': 'Central African Republic'          , 'value': 'cf'},
        {'text': 'Congo'                             , 'value': 'cg'},
        {'text': 'Switzerland'                       , 'value': 'ch'},
        {'text': 'Ivory Coast'                       , 'value': 'ci'},
        {'text': 'Cook Islands'                      , 'value': 'ck'},
        {'text': 'Chile'                             , 'value': 'cl'},
        {'text': 'Cameroon'                          , 'value': 'cm'},
        {'text': 'China'                             , 'value': 'cn'},
        {'text': 'Colombia'                          , 'value': 'co'},
        {'text': 'Costa Rica'                        , 'value': 'cr'},
        {'text': 'Cuba'                              , 'value': 'cu'},
        {'text': 'Cape Verde'                        , 'value': 'cv'},
        {'text': 'Christmas Island'                  , 'value': 'cx'},
        {'text': 'Cyprus'                            , 'value': 'cy'},
        {'text': 'Czech Republic'                    , 'value': 'cz'},
        {'text': 'Germany'                           , 'value': 'de'},
        {'text': 'Djibouti'                          , 'value': 'dj'},
        {'text': 'Denmark'                           , 'value': 'dk'},
        {'text': 'Dominica'                          , 'value': 'dm'},
        {'text': 'Dominican Republic'                , 'value': 'do'},
        {'text': 'Algeria'                           , 'value': 'dz'},
        {'text': 'Ecuador'                           , 'value': 'ec'},
        {'text': 'Estonia'                           , 'value': 'ee'},
        {'text': 'Egypt'                             , 'value': 'eg'},
        {'text': 'Western Sahara'                    , 'value': 'eh'},
        {'text': 'Eritrea'                           , 'value': 'er'},
        {'text': 'Spain'                             , 'value': 'es'},
        {'text': 'Ethiopia'                          , 'value': 'et'},
        {'text': 'Finland'                           , 'value': 'fi'},
        {'text': 'Fiji'                              , 'value': 'fj'},
        {'text': 'Falkland Islands'                  , 'value': 'fk'},
        {'text': 'Micronesia'                        , 'value': 'fm'},
        {'text': 'Faroe Islands'                     , 'value': 'fo'},
        {'text': 'France'                            , 'value': 'fr'},
        {'text': 'Gabon'                             , 'value': 'ga'},
        {'text': 'Great Britain'                     , 'value': 'gb'},
        {'text': 'Grenada'                           , 'value': 'gd'},
        {'text': 'Georgia'                           , 'value': 'ge'},
        {'text': 'French Guyana'                     , 'value': 'gf'},
        {'text': 'Guernsey'                          , 'value': 'gg'},
        {'text': 'Ghana'                             , 'value': 'gh'},
        {'text': 'Gibraltar'                         , 'value': 'gi'},
        {'text': 'Greenland'                         , 'value': 'gl'},
        {'text': 'Gambia'                            , 'value': 'gm'},
        {'text': 'Guinea'                            , 'value': 'gn'},
        {'text': 'Guadeloupe'                        , 'value': 'gp'},
        {'text': 'Equatorial Guinea'                 , 'value': 'gq'},
        {'text': 'Greece'                            , 'value': 'gr'},
        {'text': 'S. Georgia & S. Sandwich Isls.'    , 'value': 'gs'},
        {'text': 'Guatemala'                         , 'value': 'gt'},
        {'text': 'Guam (USA)'                        , 'value': 'gu'},
        {'text': 'Guinea Bissau'                     , 'value': 'gw'},
        {'text': 'Guyana'                            , 'value': 'gy'},
        {'text': 'Hong Kong'                         , 'value': 'hk'},
        {'text': 'Heard and McDonald Islands'        , 'value': 'hm'},
        {'text': 'Honduras'                          , 'value': 'hn'},
        {'text': 'Croatia'                           , 'value': 'hr'},
        {'text': 'Haiti'                             , 'value': 'ht'},
        {'text': 'Hungary'                           , 'value': 'hu'},
        {'text': 'Indonesia'                         , 'value': 'id'},
        {'text': 'Ireland'                           , 'value': 'ie'},
        {'text': 'Israel'                            , 'value': 'il'},
        {'text': 'Isle of Man'                       , 'value': 'im'},
        {'text': 'India'                             , 'value': 'in'},
        {'text': 'British Indian Ocean Territory'    , 'value': 'io'},
        {'text': 'Iraq'                              , 'value': 'iq'},
        {'text': 'Iran'                              , 'value': 'ir'},
        {'text': 'Iceland'                           , 'value': 'is'},
        {'text': 'Italy'                             , 'value': 'it'},
        {'text': 'Jersey'                            , 'value': 'je'},
        {'text': 'Jamaica'                           , 'value': 'jm'},
        {'text': 'Jordan'                            , 'value': 'jo'},
        {'text': 'Japan'                             , 'value': 'jp'},
        {'text': 'Kenya'                             , 'value': 'ke'},
        {'text': 'Kyrgyz Republic (Kyrgyzstan)'      , 'value': 'kg'},
        {'text': 'Cambodia, Kingdom of'              , 'value': 'kh'},
        {'text': 'Kiribati'                          , 'value': 'ki'},
        {'text': 'Comoros'                           , 'value': 'km'},
        {'text': 'Saint Kitts & Nevis Anguilla'      , 'value': 'kn'},
        {'text': 'North Korea'                       , 'value': 'kp'},
        {'text': 'South Korea'                       , 'value': 'kr'},
        {'text': 'Kuwait'                            , 'value': 'kw'},
        {'text': 'Cayman Islands'                    , 'value': 'ky'},
        {'text': 'Kazakhstan'                        , 'value': 'kz'},
        {'text': 'Laos'                              , 'value': 'la'},
        {'text': 'Lebanon'                           , 'value': 'lb'},
        {'text': 'Saint Lucia'                       , 'value': 'lc'},
        {'text': 'Liechtenstein'                     , 'value': 'li'},
        {'text': 'Sri Lanka'                         , 'value': 'lk'},
        {'text': 'Liberia'                           , 'value': 'lr'},
        {'text': 'Lesotho'                           , 'value': 'ls'},
        {'text': 'Lithuania'                         , 'value': 'lt'},
        {'text': 'Luxembourg'                        , 'value': 'lu'},
        {'text': 'Latvia'                            , 'value': 'lv'},
        {'text': 'Libya'                             , 'value': 'ly'},
        {'text': 'Morocco'                           , 'value': 'ma'},
        {'text': 'Monaco'                            , 'value': 'mc'},
        {'text': 'Moldavia'                          , 'value': 'md'},
        {'text': 'Montenegro'                        , 'value': 'me'},
        {'text': 'Saint Martin (French)'             , 'value': 'mf'},
        {'text': 'Madagascar'                        , 'value': 'mg'},
        {'text': 'Marshall Islands'                  , 'value': 'mh'},
        {'text': 'Macedonia'                         , 'value': 'mk'},
        {'text': 'Mali'                              , 'value': 'ml'},
        {'text': 'Myanmar'                           , 'value': 'mm'},
        {'text': 'Mongolia'                          , 'value': 'mn'},
        {'text': 'Macau'                             , 'value': 'mo'},
        {'text': 'Northern Mariana Islands'          , 'value': 'mp'},
        {'text': 'Martinique (French)'               , 'value': 'mq'},
        {'text': 'Mauritania'                        , 'value': 'mr'},
        {'text': 'Montserrat'                        , 'value': 'ms'},
        {'text': 'Malta'                             , 'value': 'mt'},
        {'text': 'Mauritius'                         , 'value': 'mu'},
        {'text': 'Maldives'                          , 'value': 'mv'},
        {'text': 'Malawi'                            , 'value': 'mw'},
        {'text': 'Mexico'                            , 'value': 'mx'},
        {'text': 'Malaysia'                          , 'value': 'my'},
        {'text': 'Mozambique'                        , 'value': 'mz'},
        {'text': 'Namibia'                           , 'value': 'na'},
        {'text': 'New Caledonia (French)'            , 'value': 'nc'},
        {'text': 'Niger'                             , 'value': 'ne'},
        {'text': 'Norfolk Island'                    , 'value': 'nf'},
        {'text': 'Nigeria'                           , 'value': 'ng'},
        {'text': 'Nicaragua'                         , 'value': 'ni'},
        {'text': 'Netherlands'                       , 'value': 'nl'},
        {'text': 'Norway'                            , 'value': 'no'},
        {'text': 'Nepal'                             , 'value': 'np'},
        {'text': 'Nauru'                             , 'value': 'nr'},
        {'text': 'Niue'                              , 'value': 'nu'},
        {'text': 'New Zealand'                       , 'value': 'nz'},
        {'text': 'Oman'                              , 'value': 'om'},
        {'text': 'Panama'                            , 'value': 'pa'},
        {'text': 'Peru'                              , 'value': 'pe'},
        {'text': 'Polynesia (French)'                , 'value': 'pf'},
        {'text': 'Papua New Guinea'                  , 'value': 'pg'},
        {'text': 'Philippines'                       , 'value': 'ph'},
        {'text': 'Pakistan'                          , 'value': 'pk'},
        {'text': 'Poland'                            , 'value': 'pl'},
        {'text': 'Saint Pierre and Miquelon'         , 'value': 'pm'},
        {'text': 'Pitcairn Island'                   , 'value': 'pn'},
        {'text': 'Puerto Rico'                       , 'value': 'pr'},
        {'text': 'Palestinian Territory (Occupied)'  , 'value': 'ps'},
        {'text': 'Portugal'                          , 'value': 'pt'},
        {'text': 'Palau'                             , 'value': 'pw'},
        {'text': 'Paraguay'                          , 'value': 'py'},
        {'text': 'Qatar'                             , 'value': 'qa'},
        {'text': 'Reunion (French)'                  , 'value': 're'},
        {'text': 'Romania'                           , 'value': 'ro'},
        {'text': 'Serbia'                            , 'value': 'rs'},
        {'text': 'Russian Federation'                , 'value': 'ru'},
        {'text': 'Rwanda'                            , 'value': 'rw'},
        {'text': 'Saudi Arabia'                      , 'value': 'sa'},
        {'text': 'Solomon Islands'                   , 'value': 'sb'},
        {'text': 'Seychelles'                        , 'value': 'sc'},
        {'text': 'Sudan'                             , 'value': 'sd'},
        {'text': 'Sweden'                            , 'value': 'se'},
        {'text': 'Singapore'                         , 'value': 'sg'},
        {'text': 'Saint Helena'                      , 'value': 'sh'},
        {'text': 'Slovenia'                          , 'value': 'si'},
        {'text': 'Svalbard Jan Mayen Islands'        , 'value': 'sj'},
        {'text': 'Slovak Republic'                   , 'value': 'sk'},
        {'text': 'Sierra Leone'                      , 'value': 'sl'},
        {'text': 'San Marino'                        , 'value': 'sm'},
        {'text': 'Senegal'                           , 'value': 'sn'},
        {'text': 'Somalia'                           , 'value': 'so'},
        {'text': 'Suriname'                          , 'value': 'sr'},
        {'text': 'Saint Tome and Principe'           , 'value': 'st'},
        {'text': 'El Salvador'                       , 'value': 'sv'},
        {'text': 'Syria'                             , 'value': 'sy'},
        {'text': 'Swaziland'                         , 'value': 'sz'},
        {'text': 'Turks and Caicos Islands'          , 'value': 'tc'},
        {'text': 'Chad'                              , 'value': 'td'},
        {'text': 'French Southern Territories'       , 'value': 'tf'},
        {'text': 'Togo'                              , 'value': 'tg'},
        {'text': 'Thailand'                          , 'value': 'th'},
        {'text': 'Tadjikistan'                       , 'value': 'tj'},
        {'text': 'Tokelau'                           , 'value': 'tk'},
        {'text': 'Timor-Leste'                       , 'value': 'tl'},
        {'text': 'Turkmenistan'                      , 'value': 'tm'},
        {'text': 'Tunisia'                           , 'value': 'tn'},
        {'text': 'Tonga'                             , 'value': 'to'},
        {'text': 'Turkey'                            , 'value': 'tr'},
        {'text': 'Trinidad and Tobago'               , 'value': 'tt'},
        {'text': 'Tuvalu'                            , 'value': 'tv'},
        {'text': 'Taiwan'                            , 'value': 'tw'},
        {'text': 'Tanzania'                          , 'value': 'tz'},
        {'text': 'Ukraine'                           , 'value': 'ua'},
        {'text': 'Uganda'                            , 'value': 'ug'},
        {'text': 'United Kingdom'                    , 'value': 'uk'},
        {'text': 'USA Minor Outlying Islands'        , 'value': 'um'},
        {'text': 'United States'                     , 'value': 'us'},
        {'text': 'Uruguay'                           , 'value': 'uy'},
        {'text': 'Uzbekistan'                        , 'value': 'uz'},
        {'text': 'Holy See (Vatican City State)'     , 'value': 'va'},
        {'text': 'Saint Vincent & Grenadines'        , 'value': 'vc'},
        {'text': 'Venezuela'                         , 'value': 've'},
        {'text': 'Virgin Islands (British)'          , 'value': 'vg'},
        {'text': 'Virgin Islands (USA)'              , 'value': 'vi'},
        {'text': 'Vietnam'                           , 'value': 'vn'},
        {'text': 'Vanuatu'                           , 'value': 'vu'},
        {'text': 'Wallis and Futuna Islands'         , 'value': 'wf'},
        {'text': 'Samoa'                             , 'value': 'ws'},
        {'text': 'Yemen'                             , 'value': 'ye'},
        {'text': 'Mayotte'                           , 'value': 'yt'},
        {'text': 'South Africa'                      , 'value': 'za'},
        {'text': 'Zambia'                            , 'value': 'zm'},
        {'text': 'Zimbabwe'                          , 'value': 'zw'}
    ];


