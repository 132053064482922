<block-ui>
    <form class="mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
          fxFlex="1 0 auto" name="form" [formGroup]="form">

<!--        <div class="h2 mb-0">Payout Information</div>-->
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <h2>
                User Balance : {{this.userBalance}}<img src="assets/images/symbol/nudles_off.png" class="nn_off">
                <img *ngIf="this.balanceRefresh" src="/assets/images/server-refresh.gif" width="20" class="nn_off">
            </h2>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label>First name</mat-label>
                <input matInput formControlName="firstName" required>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>First Name is required!</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Last name</mat-label>
                <input matInput formControlName="lastName" required>
                <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                <mat-error>Last Name is required!</mat-error>
            </mat-form-field>

        </div>

        <div fxLayout="row wrap" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Address</mat-label>
                <textarea matInput formControlName="address" required>
	                1600 Amphitheatre Pkwy
	            </textarea>
                <mat-error>Address is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="33">
                <mat-label>City</mat-label>
                <input matInput formControlName="city" required>
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error>City is required!</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="34" class="px-8">
                <mat-label>State</mat-label>
                <input matInput formControlName="state" required>
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error>State is required!</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="33">
                <mat-label>Postal Code</mat-label>
                <input matInput #postalCode
                       formControlName="postalCode" required>
                <mat-icon matSuffix class="secondary-text">markunread_mailbox</mat-icon>
                <mat-error>Postal Code is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="50">
                <mat-label>Country</mat-label>
                <input
                    matInput
                    formControlName="country"
                    [matAutocomplete]="auto"
                    required>
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of this.filteredOptions | async" [value]="option.text">
                        {{option.text}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error>Country is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="px-8">
                <mat-label>Transaction ID</mat-label>
                <input matInput formControlName="transactionID" required>
                <mat-icon matSuffix class="secondary-text">payment</mat-icon>
                <mat-error>Transaction ID is required!</mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">

            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label>Payment method</mat-label>
                <mat-select formControlName="payment" required>
                    <mat-option [value]="'paxum'">
                        paxum
                    </mat-option>
                    <mat-option [value]="'cosmopay'">
                        cosmopay
                    </mat-option>
                    <mat-option [value]="'bitcoin'">
                        bitcoin
                    </mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">payment</mat-icon>
                <mat-error>Payment method is required!</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
                <mat-label>Account ID</mat-label>
                <input matInput formControlName="accountId" required>
                <mat-error>Account ID is required!</mat-error>
            </mat-form-field>

        </div>

        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label>Amount To Cash Out</mat-label>
                <input matInput
                       type="number"
                       formControlName="fromCash"
                       required
                       readonly="{{this.balanceRefresh}}"
                       [(ngModel)]="sendingBalance"
                >
                <mat-icon matSuffix class="{{this.balanceRefresh ? 'disabled-text' : 'secondary-text'}}">
                    attach_money
                </mat-icon>
                <mat-hint align="start" style="font-size: 16px; color: #000;">
                    Payment Service Fee(20%): {{(this.sendingBalance * 0.2).toFixed(2)}}</mat-hint>
                <mat-error>Cash is required!</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="50" class="pr-4">
                <mat-label>Payout Amount</mat-label>
                <input matInput type="number" formControlName="toCash"
                       value="{{(this.sendingBalance * 0.8).toFixed(2)}}">
                <mat-icon matSuffix class="disabled-text">attach_money</mat-icon>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxLayout="column">
            <button mat-raised-button color="accent" (click)="payout()" [disabled]="isPaymentAccepted">
                Pay
            </button>
        </div>
    </form>
</block-ui>
