<div id="ngx-datatable" class="page-layout simple fullwidth docs">

  <!-- HEADER -->
  <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div class="h2 mt-16">Activity logs</div>
    </div>
  </div>
  <!-- / HEADER -->
  <ng-template #modalTemplate let-data>
    <div class="flex-container">
      <div class="modal-title">{{ data?.title }}</div>
    </div>
    <mat-dialog-content class="modal-content" [innerHTML]="data?.description"></mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
  </ng-template>



  <!-- CONTENT -->
  <div class="content_sys p-24" fusePerfectScrollbar>
    <ngx-datatable class="material" [rows]="data" [loadingIndicator]="loadingIndicator" [headerHeight]="40"
      [footerHeight]="56" [rowHeight]="50" [reorderable]="reorderable" [selectionType]="'checkbox'"
      [columnMode]="'force'" [externalPaging]="true" [count]="page.count" [offset]="page.currentPage"
      [limit]="page.limit" (page)="pageCallback($event)">
      <ngx-datatable-column name="Timestamp" prop="timestamp" [width]="20"></ngx-datatable-column>
      <ngx-datatable-column name="Message" prop="message" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column name="Details">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <button mat-mini-fab color="accent" (click)="showDetails(row, modalTemplate)"
              [ngStyle]="{ backgroundColor: row.hide ? '#FF1744' : '#039be5' }">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>