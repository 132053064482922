<div id="ngx-datatable" class="page-layout simple fullwidth docs">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
        fxLayoutAlign.gt-xs="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div class="h2 mt-16">Comments</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content_sys p-24" fusePerfectScrollbar>
        <ngx-datatable 
            class="material"
            [rows]="comments"
            [loadingIndicator]="loadingIndicator"
            [headerHeight]="40"
            [footerHeight]="56"
            [rowHeight]="50"
            [reorderable]="reorderable"
            [selectionType]="'checkbox'"
            [columnMode]="'force'"
            [externalPaging]="true"
            [count]="page.count"
            [offset]="page.currentPage"
            [limit]="page.limit"
            (page)="pageCallback($event)"
            
            >
            <ngx-datatable-column name="CreatedAt" prop="createdAt" [width]="50">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value | date:'medium'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Text" prop="text" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="Post ID" prop="post" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="Commenter" prop="commenter.username" [width]="50"></ngx-datatable-column>
            <ngx-datatable-column name="Hide comment">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <button mat-mini-fab color="accent" (click)="hideComment(row)" [matTooltip]="row.hide ? 'Unhide Comment' : 'Hide Comment'" [ngStyle]="{ backgroundColor: row.hide ? '#FF1744' : '#039be5' }">
                            <mat-icon *ngIf="!row.hide">visibility</mat-icon>
                            <mat-icon *ngIf="row.hide">visibility_off</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Hide all user comments">
                <ng-template ngx-datatable-cell-template let-row="row">
                    <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
                        <button mat-mini-fab color="accent" [ngStyle]="{ backgroundColor: row.hide_user_comment ? '#FF1744' : '#039be5' }" (click)="toggleUserComment(row)"
                            [matTooltip]="row.hide_user_comment ? 'Unhide User Comments' : 'Hide User Comments'">
                            <mat-icon *ngIf="!row.hide_user_comment">delete</mat-icon>
                            <mat-icon *ngIf="row.hide_user_comment">restore_from_trash</mat-icon>
                        </button>
                    </div>
                </ng-template>
            </ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>