import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation} from '@angular/core';
import {FormControl, UntypedFormGroup} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, fromEvent, merge, Observable, Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {fuseAnimations} from '@fuse/animations';
import {FuseConfirmDialogComponent} from '@fuse/components/confirm-dialog/confirm-dialog.component';
import {ContactsService} from '../contacts.service';
import {ContactsContactFormDialogComponent} from '../contact-form/contact-form.component';
import {MatLegacySlideToggleChange as MatSlideToggleChange} from '@angular/material/legacy-slide-toggle';
import {MatSort} from '@angular/material/sort';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {FuseUtils} from '../../../../../../@fuse/utils';

@Component({
    selector: 'contacts-contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class ContactsContactListComponent implements OnInit, OnDestroy {
    @ViewChild('dialogContent')
    dialogContent: TemplateRef<any>;

    contacts: any;
    user: any;
    dataSource: FilesDataSource | null;
    displayedColumns = ['avatar', 'name', 'contributor', 'email', 'createdAt', 'join', 'last', 'lastLogin', 'isFlagged', 'loginCount', 'buttons'];
    selectedContacts: any[];
    checkboxes: {};
    dialogRef: any;
    contributorOnly = false;
    isDeletedUserOnly = false;

    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    @ViewChild(MatSort, {static: true})
    sort: MatSort;

    @ViewChild(MatPaginator, {static: true})
    paginator: MatPaginator;

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ContactsService} _contactsService
     * @param {MatDialog} _matDialog
     */
    constructor(
        private _contactsService: ContactsService,
        public _matDialog: MatDialog,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this._contactsService, this.paginator, this.sort);
        fromEvent(this.filter.nativeElement, 'keyup')
            .pipe(
                takeUntil(this._unsubscribeAll),
                debounceTime(150),
                distinctUntilChanged()
            )
            .subscribe(() => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = this.filter.nativeElement.value;
            });

        this._contactsService.onUserDataChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(user => {
                this.user = user;
            });

        // this._contactsService.onFilterChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(() => {
        //         this._contactsService.deselectContacts();
        //     });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this._contactsService.onSearchTextChanged.next('');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Edit contact
     *
     * @param contact
     */
    editContact(contact): void {
        this.dialogRef = this._matDialog.open(ContactsContactFormDialogComponent, {
            panelClass: 'contact-form-dialog',
            data: {
                contact: contact,
                action: 'edit'
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (!response) {
                    return;
                }
                const actionType: string = response[0];
                const formData: UntypedFormGroup = response[1];
                switch (actionType) {
                    /**
                     * Save
                     */
                    case 'save':
                        // const modifiedUserData = formData.getRawValue();
                        // this.dataSource.filteredData = this.dataSource.filteredData.filter( item => {
                        //    if (item.id === modifiedUserData.id) {
                        //        item = {...modifiedUserData};
                        //    }
                        //    return item;
                        // });
                        // debugger
                        break;
                    /**
                     * Delete
                     */
                    case 'delete':

                        this.deleteContact(contact);

                        break;
                }
            });
    }

    /**
     * Delete Contact
     */
    deleteContact(contact): void {
        this.confirmDialogRef = this._matDialog.open(FuseConfirmDialogComponent, {
            disableClose: false
        });

        this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

        this.confirmDialogRef.afterClosed().subscribe(result => {
            if (result) {
                this._contactsService.deleteContact(contact);
            }
            this.confirmDialogRef = null;
        });

    }

    changeContributor($event: MatSlideToggleChange, contact: any): void {
        this._contactsService.changeContributor($event.checked, contact);
    }

    /**
     * On selected change
     *
     * @param contactId
     */
    onSelectedChange(contactId): void {
        this._contactsService.toggleSelectedContact(contactId);
    }

    /**
     * Toggle star
     *
     * @param contactId
     */
    toggleStar(contactId): void {
        if (this.user.starred.includes(contactId)) {
            this.user.starred.splice(this.user.starred.indexOf(contactId), 1);
        } else {
            this.user.starred.push(contactId);
        }

        this._contactsService.updateUserData(this.user);
    }

    restoreContact(contact: any): void {
        this._contactsService.restoreContact(contact);
    }


    // tslint:disable-next-line:typedef
    compare(a: number | string, b: number | string, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    // toggleSidebar(name): void
    // {
    //     this._fuseSidebarService.getSidebar(name).toggleOpen();
    // }

    handleMissingImage(event: ErrorEvent) {
        (event.target as HTMLImageElement).src = 'https://images-test-cdn.newbienudes.com/m/media-nn-test/pp/nopic.jpg';
    }

    onSelectContributor() {
        this.dataSource.filterContributor = this.contributorOnly;
    }

    onSelectDeleteUser() {
        this.dataSource.filterDeletedUser = this.isDeletedUserOnly;
    }
}

export class FilesDataSource extends DataSource<any> {
    private _filterChange = new BehaviorSubject('');
    private _filterChangeContributor = new BehaviorSubject(false);
    private _filterChangeDeletedUser = new BehaviorSubject(false);
    private _filteredDataChange = new BehaviorSubject('');

    private 
    /**
     * Constructor
     *
     * @param {ContactsService} _contactsService
     */
    constructor(
        private _contactsService: ContactsService,
        private _matPaginator: MatPaginator,
        private _matSort: MatSort,
    ) {
        super();
        this.filteredData = this._contactsService.contacts;
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(): Observable<any[]> {
        const displayDataChanges = [
            this._contactsService.onContactsChanged,
            this._matPaginator.page,
            this._matSort.sortChange,
            this._filterChange,
            this._filterChangeContributor,
            this._filterChangeDeletedUser
        ];
        return merge(...displayDataChanges).pipe(map(() => {
                let data = this._contactsService.contacts.slice();
                data = this.filterData(data);
                data = this.fitlerDataContributor(data);
                data = this.filterDataDeletedUser(data);
                this.filteredData = [...data];
                data = this.sortData(data);

                // Grab the page's slice of data.
                const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
                return data.splice(startIndex, this._matPaginator.pageSize);
            })
        );
    }

    /**
     * Disconnect
     */
    disconnect(): void {
    }

    sortData(data): any[] {
        if (!this._matSort.active || this._matSort.direction === '') {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | string = '';
            let propertyB: number | string = '';
            switch (this._matSort.active) {
                case 'name':
                    [propertyA, propertyB] = [a.name, b.name];
                    break;
                case 'contributor':
                    [propertyA, propertyB] = [a.contributor, b.contributor];
                    break;
                case 'email':
                    [propertyA, propertyB] = [a.email, b.email];
                    break;
                case 'createdAt':
                    [propertyA, propertyB] = [a.createdAt, b.createdAt];
                    break;
                case 'lastLogin':
                    [propertyA, propertyB] = [a.lastLogin, b.lastLogin];
                    break;
                case 'isFlagged':
                    [propertyA, propertyB] = [a.isFlagged, b.isFlagged];
                    break;
                case 'loginCount':
                    [propertyA, propertyB] = [a.loginCount, b.loginCount];
                    break;
                default:
                    return 0;
            }
            if (this._matSort.active == 'createdAt' || this._matSort.active == 'lastLogin') {
                if (!propertyA) {
                    propertyA = '1970-01-01';
                }
                if (!propertyB) {
                    propertyB = '1970-01-01';
                }
            }
            if (this._matSort.active == 'loginCount') {
                if (!propertyA) {
                    propertyA = 0;
                }
                if (!propertyB) {
                    propertyB = 0;
                }
            }

            const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
            const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

            return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
        });
    }

    get filteredData(): any {
        return this._filteredDataChange.value;
    }

    set filteredData(value: any) {
        this._filteredDataChange.next(value);
    }

    // Filter
    get filter(): string {
        return this._filterChange.value;
    }

    set filter(filter: string) {
        this._filterChange.next(filter);
    }

    // Filter Contributor
    get filterContributor(): boolean {
        return this._filterChangeContributor.value;
    }

    set filterContributor(filter: boolean) {
        this._filterChangeContributor.next(filter);
    }

    filterData(data): any {
        if (!this.filter) {
            return data;
        }
        return FuseUtils.filterArrayByString(data, this.filter);
    }

    fitlerDataContributor(data): any {
        if (!this.filterContributor) {
            return data;
        }
  
        data = data.filter((item) => {
            if (item.contributor) {
                return item;
            }
        });
        return data;
    }

      //filter deleted user
    get filterDeletedUser(): boolean { 
        return this._filterChangeDeletedUser.value;
    }

    set filterDeletedUser(filter: boolean) {
        this._filterChangeDeletedUser.next(filter);
    }

    filterDataDeletedUser(data): any {
        console.log(this.filterDeletedUser)
        if (!this.filterDeletedUser) {
            return data;
        }

        console.log(this.filterDeletedUser)
  
        data = data.filter((item) => {
            if (item.isDeleted) {
                return item;
            }
        });
        return data;
    }

}
