import { Component, OnInit } from "@angular/core";
import { SplashScreenService } from "./splash-screen.service";
import { environment } from "environments/environment";

@Component({
    selector: "app-splash-screen",
    templateUrl: "./splash-screen.component.html",
    styleUrls: ["./splash-screen.component.scss"],
})
export class SplashScreenComponent implements OnInit {
    siteName: string;
    isVisible: boolean = true;

    constructor(private splashScreenService: SplashScreenService) {
        this.siteName = environment.sitename;
    }

    ngOnInit(): void {
        this.splashScreenService.visibility$.subscribe((visible) => {
            this.isVisible = visible;
        });
    }

    hide(): void {
        this.isVisible = false;
    }
}