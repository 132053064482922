import {NgModule} from '@angular/core';
import {CommonModule, NgForOf, NgIf} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {ReportDialogComponent} from './report-dialog/report-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {ReactiveFormsModule} from '@angular/forms';
import {AppMaterialModule} from '../../../app-material.module';
import {FuseModule} from '@fuse/fuse.module';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule, FuseWidgetModule} from '@fuse/components';
import {ContactsComponent} from './contacts/contacts.component';
import {BlockUIModule} from 'ng-block-ui';
import {PostsComponent} from './posts/posts.component';
import {ReportComponent} from './report/report.component';
import {ProfilePhotosVideosComponent} from './posts/tab/profile-photos-videos/profile-photos-videos.component';
import {PopupPostComponent} from './popup-post/popup-post.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {TagsComponent} from './tags/tags.component';
import {TagPopupComponent} from './tag-popup/tag-popup.component';
import {SettingsComponent} from './settings/settings.component';
import {ConfirmDialogComponent} from './confirm-dialog/confirm-dialog.component';
import {ContactsContactFormDialogComponent} from './contacts/contact-form/contact-form.component';
import {ContactsMainSidebarComponent} from './contacts/sidebars/main/main.component';
import {ContactsSelectedBarComponent} from './contacts/selected-bar/selected-bar.component';
import {ContactsContactListComponent} from './contacts/contact-list/contact-list.component';
import {PayoutComponent} from './payout/payout.component';
import {PayInformComponent} from './payout/pay-inform/pay-inform.component';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {SyslogComponent} from './syslog/syslog.component';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {LoginLogComponent} from './login-log/login-log.component';
import {TooltipModule} from 'ng2-tooltip-directive';
import {CommentsComponent} from './comments/comments.component';
import {FlexModule} from '@angular/flex-layout';
import {AnalyticsComponent} from './analytics/analytics.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import {ChartsModule} from 'ng2-charts';
import {AnalyticsDashboardService} from '../../apps/dashboards/analytics/analytics.service';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { ContentHtmlComponent } from './syslog/content-html/content-html.component';
import { AdminCommentsComponent } from './hide-comments/comments.component';
import { HideUserCommentComponent } from './hide-comments/hide-user-comment/hide-user-comment.component';

@NgModule({
    declarations: [
        ReportDialogComponent,
        ContactsComponent,
        PostsComponent,
        ReportComponent,
        ProfilePhotosVideosComponent,
        PopupPostComponent,
        TagsComponent,
        TagPopupComponent,
        SettingsComponent,
        ConfirmDialogComponent,
        ContactsContactFormDialogComponent,
        ContactsMainSidebarComponent,
        ContactsSelectedBarComponent,
        ContactsContactListComponent,
        PayoutComponent,
        PayInformComponent,
        SyslogComponent,
        LoginLogComponent,
        CommentsComponent,
        AnalyticsComponent,
        ContentHtmlComponent,
        AdminCommentsComponent,
        HideUserCommentComponent
    ],
    imports: [
        CommonModule,
        NgxDatatableModule,
        MatIconModule,
        MatCheckboxModule,
        MatDialogModule,
        MatButtonModule,
        MatAutocompleteModule,
        MatSortModule,
        MatPaginatorModule,
        ReactiveFormsModule,
        AppMaterialModule,
        FuseModule,
        FuseProgressBarModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        BlockUIModule.forRoot(),
        MatDatepickerModule,
        MatTooltipModule,
        TooltipModule,
        FlexModule,
        MatIconModule,
        FlexModule,
        ReactiveFormsModule,
        ChartsModule,
        NgxChartsModule,
        FuseWidgetModule,
        NgxDaterangepickerMd.forRoot()
    ],
    providers: [
        AnalyticsDashboardService
    ],
    bootstrap: [ContentHtmlComponent]
})
export class HomeModule {
}
