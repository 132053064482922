<h2 mat-dialog-title>Report</h2>
<mat-dialog-content class="mat-typography">
    <div class="media" *ngIf="data?.report.media">
        <img *ngIf="data?.report.type === 2"
             [src]="data?.report.media ? data?.report.media : 'assets/images/avatars/profile.jpg'">
        <vg-player *ngIf="data?.report.type === 3">
            <video [vgMedia]="media" #media id="singleVideo" preload="auto" controls>
                <source [src]="data?.report.media" type="video/3gpp">
            </video>
        </vg-player>
    </div>
    <h3><b>Title</b>: {{data.report.title}}</h3>
    <p *ngIf="data?.report.description"><b>Replied by Admin</b>: {{data.report.description}}</p>
    <div class="separate-bar" fxLayout="row" fxLayoutAlign="center center"></div>
    <app-comments [comments]="comments"></app-comments>
    <div>
        <div *ngIf="comments.length < this.commentTotal">
            <button mat-button class="load-comment-btn" (click)="loadMoreComments(data.report._id.toString())">Load
                More...</button>
        </div>
    </div>
    <form class="w-100-p" [formGroup]="postForm" novalidate fxLayout="column" fxLayoutAlign="start">
        <mat-form-field appearance="outline">
            <input matInput placeholder="Write a Reply..." formControlName="title" [readonly]="!data.report.open">
        </mat-form-field>
        <button mat-raised-button class="small" color="primary" [disabled]="!data.report.open"
                (click)="onSubmit()" *ngIf="data.report.description">Add Comment</button>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Close</button>
    <button mat-button (click)="SendReply()" *ngIf="!data.report.description">Reply</button>
</mat-dialog-actions>