import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PostService {

    uri = environment.apiURL + 'contacts/';
    onPostChanged: BehaviorSubject<any>;
    posts = [];

    constructor(private http: HttpClient) {
    }
    
    getPostAll(param = {}): Observable<any> {
        return this.http.post(this.uri + 'getPosts', param);
    }
}
