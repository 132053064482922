import { Component, Input, OnInit, ViewEncapsulation, TemplateRef  } from '@angular/core';
import { Router } from "@angular/router";
import { ActivityLogsService } from "@shared/services/activity-logs.service";
import { ActivityLogsModel } from "@shared/models/activity-logs.model"
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface ActivityLogsResponse {
  data: {
    data: ActivityLogsModel[];
    pagination: {
      total: number;
      page: number;
      limit: number;
      totalPages: number;
    };
  };
  error: any;
  success: any;
}

@Component({
  selector: 'app-activity-logs',
  templateUrl: './activity-logs.component.html',
  styleUrls: ['./activity-logs.component.scss']
})
export class ActivityLogsComponent {

  public safeDescription: SafeHtml;
  @Input() data: any[];
  page = {
    limit: 20,
    count: 0,
    offset: 0,
    currentPage: 1,
    totalPages: 0
  };

  reorderable: boolean;
  loadingIndicator: boolean = true;

   constructor(
    private router: Router,
    private activityLogsService: ActivityLogsService,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit(): void {
    this.handleGetActivityLogs({ page: 1, limit: 20 });
  }

  pageCallback(event: any): void {
    const currentPage = event.offset + 1;
    this.handleGetActivityLogs({
      page: currentPage,
      limit: this.page.limit
    });
  }

 showDetails(data: any, template: TemplateRef<any>) {
  console.log(data)
    this.dialog.open(template, {
      data: {
        title: data?.title,
        description: this.sanitizer.bypassSecurityTrustHtml(data?.description),
      },
      width: '500px'
    });
  }

  getDescription(data) {
    let message = ""
    switch(data?.activityType) {
      case "view_post":
      message += `<p> ${data?.message} </p>`;
      if (data?.metadata?.thumbnail) {
        message += `<img src="${data?.metadata?.thumbnail}" />`
      }
      message += `<p style="font-size:11;font-style: italic;">${ data?.timestamp }</p>`
      break;
      case "bug_report":
      message += `<p> ${data?.metadata.bug_report} </p>`;
      if (data?.metadata?.thumbnail) {
        message += `<img src="${data?.metadata?.thumbnail}" />`
      }
      message += `<p style="font-size:11;font-style: italic;">${ data?.timestamp }</p>`
      break;
      default :
      message += `<p>${data?.message}</p>`;
      message += `<p style="font-size:11;font-style: italic;">${ data?.timestamp }</p>`
    }
    return message
  }

  handleGetActivityLogs(query?: { page: number; limit: number }) {
     this.activityLogsService.getAll(query).subscribe((response: ActivityLogsResponse) => {
      if (response?.success) {
        // Update pagination data based on API response
        this.page.count = response.data.pagination.total;
        this.page.limit = response.data.pagination.limit;
        this.page.currentPage = response.data.pagination.page - 1;
        this.page.totalPages = response.data.pagination.totalPages;
        this.data = response.data.data;
      }
    });
  }
}
