<div id="ngx-datatable" class="page-layout simple fullwidth docs">

  <!-- HEADER -->
  <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
    fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
      <div class="h2 mt-16">Hidden User Comments</div>
    </div>
  </div>
  <!-- / HEADER -->

  <!-- CONTENT -->
  <div class="content_sys p-24" fusePerfectScrollbar>
    <ngx-datatable class="material" [rows]="users" [loadingIndicator]="loadingIndicator" [headerHeight]="40"
      [footerHeight]="56" [rowHeight]="50" [reorderable]="reorderable" [selectionType]="'checkbox'"
      [columnMode]="'force'" [externalPaging]="true" [count]="page.count" [offset]="page.currentPage"
      [limit]="page.limit" (page)="pageCallback($event)">
      <ngx-datatable-column name="CreatedAt" prop="createdAt" [width]="50">
        <ng-template let-value="value" ngx-datatable-cell-template>
          <span>{{value | date:'medium'}}</span>
        </ng-template>
      </ngx-datatable-column>
      <ngx-datatable-column name="User Id" prop="user_id.email" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column name="Email" prop="user_id.email" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column name="Username" prop="user_id.username" [width]="50"></ngx-datatable-column>
      <ngx-datatable-column name="Actions">
        <ng-template ngx-datatable-cell-template let-row="row">
          <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="10px">
            <button mat-mini-fab color="accent" (click)="toggleUserComment(row)"
              matTooltip="Restore user comments">
              <mat-icon>restore</mat-icon>
            </button>
          </div>
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>
</div>