<div id="photos-videos" class="p-24">
    <div class="period" >
        <div class="period-title">
            <span class="name">Posts</span>
        </div>

        <div class="period-media" fxLayout="row wrap">
            <div class="media" *ngFor="let post of posts" (click)="openPopup(post)">
                <img class="preview" [src]="post.media" title="{{post.title}}" *ngIf="post?.type === 2">
                <div *ngIf="post?.type === 3" class="preview-media">
                    <video width="256" (playing)="onPlayingVideo($event, media)"   preload="auto"  #media>
                        <source [src]="post?.media" type="video/3gpp">
                    </video>
                </div>
                <img class="preview" src="/assets/images/placeholde.jpg" *ngIf="post?.type !== 2 && post?.type !== 3">
                <div class="title" *ngIf="post?.type === 2">{{post.title}}</div>
            </div>
            <div class="pagination-layout">
                <mat-paginator [length]="page.count" [pageSize]="page.limit"
                (page)="handleLoadPost($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>
