import {Injectable} from '@angular/core';
import {Socket} from 'ngx-socket-io';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {environment} from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SocketService {

    onMsg: BehaviorSubject<any>;
    realData: BehaviorSubject<any>;
    onDisconnect: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    constructor(private socket: Socket) {
        this.onMsg = new BehaviorSubject<any>(null);
        this.realData = new BehaviorSubject<any>(null);
        this.socket.on('disconnect', () => {
            this.onDisconnect.next(true);
        })
    }

    /**
     *  Real Data Subscription
     * @param param
     */

    public setOnRealData(param) {
        this.realData.next(param);
    }

    /**
     * send Real Data
     * @param eventName
     * @param args
     */

    public emitData(eventName, args) {
        this.socket.emit(eventName, args);
    }

    /**
     * new post notification
     */

    public newPostNotification = () => {
        return Observable.create((observer) => {
            this.socket.on('newpost', (param) => {
                observer.next(param);
            });
        });
    }

    /**
     * real notification for deleting, updating, adding comment, liking, bookmarking
     * @constructor
     */

    public PostPush = () => {
        return Observable.create((observer) => {
            this.socket.on('postPush', (param) => {
                console.log(param,"====")
                observer.next(param);
            });
        });
    }


    public checkedRoom = () => {
        return Observable.create((observer) => {
            this.socket.on('remove-room', (param) => {
                observer.next(param);
            });
        });
    }

    /**
     * chatting push
     */

    public getPush = () => {
        return Observable.create((observer) => {
            this.socket.on('webpush', (param) => {
                observer.next(param);
            });
        });
    }

    /**
     * chatting part
     * @param id
     */

    public getMessages = (id = '') => {
        return Observable.create((observer) => {
            this.socket.on('new-message', (message) => {
                observer.next(message);
            });
        });
    }

    /**
     * chatting getReadMessage
     * @param id
     */

    public getReadMessage = (id = '') => {
        return Observable.create((observer) => {
            this.socket.on('read-message', (message) => {
                observer.next(message);
            });
        });
    }

    /**
     * chatting getMsg
     */

    public getMsg = () => {
        return Observable.create((observer) => {
            this.socket.on('new-msg', (message) => {
                observer.next(message);
            });
        });
    }

    /**
     * site reconnected while disconnecting
     */

    public reconnected = () => {
        return Observable.create((observer) => {
            this.socket.on('reconnected', (message) => {
                observer.next(message);
            });
        });
    }
}
