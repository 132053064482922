import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs';
import {SettingsService} from '../../../../shared/services/settings/settings.service';
import {takeUntil} from 'rxjs/operators';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnDestroy {

    form: UntypedFormGroup;
    token = '';
    rationPosts: any;

    private _unsubscribeAll: Subject<any>;


    constructor(
        private _formBuilder: UntypedFormBuilder,
        private settingsService: SettingsService,
        private matSnackBar: MatSnackBar
    ) {
        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {

        this.settingsService.getSettingsData().pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                if (data['success'] && data['data']) {
                    this.form.setValue({currency: data['data'].setting.currency});
                }
            });

        this.form = this._formBuilder.group({
            currency: [this.token]
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    submit(): void {
        const params = this.form.getRawValue();
        this.settingsService.insertSettings(params).subscribe((data) => {
            this.matSnackBar.open('successfully done', '', {
                verticalPosition: 'top',
                horizontalPosition: 'right',
                politeness: 'assertive',
                duration: 3000
            });
        });
    }

    updateStatus(): void {

    }
}
