import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { TranslateModule } from '@ngx-translate/core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AuthModule } from 'app/main/content/auth/auth.module';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';
import { HomeModule } from './main/content/home/home.module';
import {AuthGuard} from './shared/guards/auth/auth.guard';
import {AuthService} from './shared/services/auth/auth.service';
import {AppMaterialModule} from './app-material.module';
import {ContactsService} from "./main/content/home/contacts/contacts.service";
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import {SocketIoModule, SocketIoConfig} from 'ngx-socket-io';
import {environment} from '../environments/environment';
const config: SocketIoConfig = {url: environment.socketUrl, options: {transports: ['websocket'], reconnection: true}};

@NgModule({
    declarations: [
        AppComponent,
        SplashScreenComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        // RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),
        AppRoutingModule,
        TranslateModule.forRoot(),
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        AppMaterialModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        SocketIoModule.forRoot(config),
        // App modules
        LayoutModule,
        AppStoreModule,
        AuthModule,
        HomeModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
        AuthGuard,
        AuthService,
        ContactsService,
        // UserService,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    ]
})
export class AppModule
{
}
