<div class="comment mb-12" fxLayout="row" fxFlexFill fxLayoutAlign="space-between space-between"
  *ngFor="let comment of comments">
  <div class="message">
    <a (click)="gotoUserPage(comment.commenter?.username)" class="userlink">
      <img [src]="comment.commenter?.avatar ? comment.commenter.avatar: 'assets/images/avatars/profile.jpg'"
        class="avatar_c" (error)="this.handleMissingImage($event)" />
      {{comment.isAdmin? 'Admin' : comment?.commenter?.username}}
    </a>
    <span *ngIf="!comment.isdetail" [innerHtml]="highlight(comment?.text)"></span>
    <span *ngIf="comment?.isdetail">{{highlight(comment?.text)}}</span>
    <a (click)="viewCommentDetail(comment)" color="accent" class="view-detail"
      *ngIf="(comment?.text?.length > 125) && !comment.isdetail">&nbsp;more</a>
    <div class="pt-4 secondary-text">{{this.formatPeriod(comment?.createdAt)}}</div>
  </div>
</div>