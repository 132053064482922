
<div id="ngx-datatable" class="page-layout simple fullwidth docs">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div class="h2 mt-16">Posts</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <block-ui>
        <div class="content p-24">
            <mat-tab-group #profileTabs dynamicHeight="true">
                <mat-tab label="List">
                    <ng-template mat-tab-label>
                        <mat-icon>list</mat-icon>
                    </ng-template>
                    <ngx-datatable
                        class="material"
                        [selected]="selected"
                        (select)='onSelect($event)'
                        (activate)="onActivate($event)"
                        [rows]="rows"
                        [loadingIndicator]="loadingIndicator"
                        [headerHeight]="48"
                        [footerHeight]="56"
                        [rowHeight]="80"
                        [scrollbarH]="true"
                        [reorderable]="reorderable"
                        [selectionType]="'checkbox'"
                        columnMode="force"
                        [externalPaging]="true"
                        [count]="page.count"
                        [offset]="page.offset"
                        [limit]="page.limit"
                        (page)="pageCallback($event)"
                        (sort)="onSort($event)"
                    >

                        <ngx-datatable-column
                            [sortable]="false">
                            <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                                         let-selectFn="selectFn">
                                <mat-checkbox [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"></mat-checkbox>
                            </ng-template>

                            <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                                         let-onCheckboxChangeFn="onCheckboxChangeFn">
                                <mat-checkbox [checked]="isSelected" (change)="onCheckboxChangeFn($event)"></mat-checkbox>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Media" [width]="200" [canAutoResize]="false">
                            <ng-template ngx-datatable-cell-template let-row="row">
                               <img class="width100"  *ngIf="row?.type === 2"
                                     src="{{row?.media ? row?.media : 'assets/images/avatars/placeholde.jpg'}}">
                                <video class="width100 mediaTag"  *ngIf="row?.type === 3" id="singleVideo" preload="auto" controls style="max-width: 100%;"  #media>
                                    <source [src]="row?.media" type="video/3gpp">
                                </video>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Owner" prop="owner.username"></ngx-datatable-column>
                        <ngx-datatable-column name="Title" prop="title" ></ngx-datatable-column>
                        <ngx-datatable-column name="Likes" prop="likeCount"></ngx-datatable-column>
                        <!-- <ngx-datatable-column name="Price to view" prop="fee"></ngx-datatable-column> -->
                        <ngx-datatable-column name="Price to view" prop="fee">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span>{{ row.fee }}</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <ngx-datatable-column name="Followers only">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span *ngIf="row.feedon === '2'" style="color:rgb(8, 141, 8);">FO</span>
                            </ng-template>
                        </ngx-datatable-column>


                        <ngx-datatable-column name="Non-Nude">
                            <ng-template let-row="row" ngx-datatable-cell-template>
                                <span *ngIf="!row.containsNudity">NN</span>
                            </ng-template>
                        </ngx-datatable-column>

                        <!--                <ngx-datatable-column name="FCM" prop="fcm_token" ></ngx-datatable-column>-->
                        <ngx-datatable-column name="CreatedAt" prop="createdAt" [width]="200">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <span>{{value | date:'medium'}}</span>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="Tags" prop="hashTags" [width]="300">
                            <ng-template let-value="value" ngx-datatable-cell-template>
                                <div class="overflowinit">
                                    <span class="text-boxed accent m-2 line30" *ngFor="let v of value">{{v}}</span>
                                </div>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column name="">
                            <ng-template ngx-datatable-cell-template let-row="row">
                                <button mat-raised-button color="accent" (click)="gotoPost(row)">Edit</button>
                            </ng-template>
                        </ngx-datatable-column>
                    </ngx-datatable>
                    <button mat-raised-button type="button" color="warn" class="mt-8 mr-8" (click)="clear()">
                        Clear
                    </button>
                    <button mat-raised-button type="button" color="warn" class="mt-8" (click)="clear('all')">
                        Clear All
                    </button>
                </mat-tab>
                <mat-tab label="Grid">
                    <ng-template mat-tab-label>
                        <mat-icon>grid_on</mat-icon>
                    </ng-template>
                    <app-profile-photos-videos [loadPostData]="loadPostData" [page]="page" [dataSource]="dataSource"></app-profile-photos-videos>
                </mat-tab>
            </mat-tab-group>
        </div>
    </block-ui>
</div>
