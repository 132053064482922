import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService implements Resolve<any> {
    uri = environment.apiURL + 'analytics';
    public data: any;
    onDatasUpdated: Subject<any>;

    constructor(private http: HttpClient) {
        this.onDatasUpdated = new Subject();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getAnalytics()
            ]).then(
                ([events]: [any]) => {
                    resolve(true);
                },
                reject
            );
        });
    }

    /**
     *  get Analytics Data
     * @param type
     */

    getAnalytics(type?: { type: string; lastDays: number; firstDate: string; lastDate: string, moving: number }): Promise<any> {
        return new Promise((resolve, reject) => {
            return this.http.post(this.uri, type)
                .subscribe((response: any) => {
                    this.data = response.data;
                    this.onDatasUpdated.next(this.data);
                    resolve(this.data);
                }, reject);
        });
    }
}
