
<div id="ngx-datatable" class="page-layout simple fullwidth docs">

    <!-- HEADER -->
    <div class="header accent p-24" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div class="h2 mt-16">Email Log</div>
        </div>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content_sys p-24" fusePerfectScrollbar>
        <ngx-datatable
            class="material"
            [selected]="selected"
            (select)='onSelect($event)'
            (activate)="onActivate($event)"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [headerHeight]="40"
            [footerHeight]="56"
            [rowHeight]="50"
            [reorderable]="reorderable"
            [selectionType]="'checkbox'"
            [columnMode]="'force'"
            [externalPaging]="true"
            [count]="page.count"
            [offset]="page.offset"
            [limit]="page.limit"
            (page)="pageCallback($event)"
            (sort)="onSort($event)"
        >
            <ngx-datatable-column name="CreatedAt" prop="createdAt" [width]="50">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span>{{value | date:'medium'}}</span>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Subject" prop="subject" [width]="50"></ngx-datatable-column>
            <!-- <ngx-datatable-column name="Body" prop="content" [width]="200">
                <ng-template let-value="value" ngx-datatable-cell-template>
                    <span [matTooltip]="value" [matTooltipPosition]="'before'" (click)="openModal(value)">{{value}}</span>
                </ng-template>
            </ngx-datatable-column> -->

            <ngx-datatable-column name="Body" prop="content" [width]="200">
                <ng-template let-row="row" ngx-datatable-cell-template>
                    <span [matTooltip]="value" [matTooltipPosition]="'before'" (click)="openModal(row?.html_content)">{{row.content}}</span>
                    <!-- <span>{{ row.content }}</span> -->
                </ng-template>
            </ngx-datatable-column>


            <ngx-datatable-column name="Email" prop="email"></ngx-datatable-column>
        </ngx-datatable>
    </div>
</div>
