<div id="dashboard-analytics" class="page-layout blank">

    <div class="content">

        <div class="left mr-lg-32 ml-lg-16">

            <!-- Widget 5 -->
            <div class="pt-48 pb-24" fxLayout="row" fxLayoutGap="10px">
                <button mat-raised-button [ngClass]="{'accent': this.widgetsType === 'new'}" (click)="selectWidgetType('new')">New Sign Up</button>
                <button mat-raised-button [ngClass]="{'accent': this.widgetsType === 'login'}" (click)="selectWidgetType('login')">Login</button>
                <button mat-raised-button [ngClass]="{'accent': this.widgetsType === 'post'}" (click)="selectWidgetType('post')">Post</button>
                <button mat-raised-button [ngClass]="{'accent': this.widgetsType === 'token'}" (click)="selectWidgetType('token')">Spent Token</button>
                <button mat-raised-button [ngClass]="{'accent': this.widgetsType === 'message'}" (click)="selectWidgetType('message')">Message</button>
            </div>

            <div class="fuse-card auto-width">

                <div class="position-relative p-24"
                     fxLayout="row" fxLayoutAlign="space-between center">
                    <div fxLayout="column" fxLayoutAlign="start start">
                        <span class="h2">{{widgetLabel}}</span>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="px-16" fxLayout="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Moving Average</mat-label>
                                <mat-select class="simplified" value="{{movingAverage}}" (selectionChange)="selectMovingAverage($event.value)">
                                    <mat-option value="1">1 day</mat-option>
                                    <mat-option value="3">3 days</mat-option>
                                    <mat-option value="7">7 days</mat-option>
                                    <mat-option value="14">14 days</mat-option>
                                    <mat-option value="30">30 days</mat-option>
                                    <mat-option value="90">90 days</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                             (click)="selectLastDate(1)"
                             [ngClass]="{'accent': widget5SelectedDay === 1}">
                            Yesterday
                        </div>
                        <div class="py-8 px-12 border-radius-2 line-height-1 mr-8 cursor-pointer"
                             (click)="selectLastDate(0)"
                             [ngClass]="{'accent': widget5SelectedDay === 0}">
                            Today
                        </div>
                        <div class="px-16" fxLayout="row">
                            <mat-form-field>
                                <mat-select class="simplified" value="{{widget5SelectedDay}}" (selectionChange)="selectLastDate($event.value)">
                                    <mat-option value="7">Last 7 days</mat-option>
                                    <mat-option value="28">Last 28 days</mat-option>
                                    <mat-option value="90">Last 90 days</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="px-16" fxLayout="row">
                            <mat-form-field appearance="legacy" class="w-200">
                                <input type="text"
                                       ngxDaterangepickerMd
                                       matInput
                                       [(ngModel)]="selected"
                                       [showCustomRangeLabel]="true"
                                       [alwaysShowCalendars]="true"
                                       [ranges]="ranges"
                                       [linkedCalendars]="true"
                                       [isInvalidDate] = "isInvalidDate"
                                       [showClearButton]="true"
                                       (rangeClicked)="rangeSelected"
                                       (datesUpdated)="datesUpdated($event)"
                                       placeholder="Select please..."/>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="position-relative h-500 pb-16">
                    <canvas baseChart
                            [datasets]="lineChartData"
                            [labels]="lineChartLabels"
                            [options]="lineChartOptions"
                            [colors]="lineChartColors"
                            [legend]="lineChartLegend"
                            [chartType]="'line'"
                            [plugins]="lineChartPlugins"
                    >
                    </canvas>
                </div>

            </div>
            <!-- / Widget 5 -->

        </div>
    </div>
</div>
