import {Component, Input, OnInit, OnDestroy, ViewEncapsulation, ChangeDetectorRef} from '@angular/core';
import {fuseAnimations} from '../../../../../../../@fuse/animations';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {PopupPostComponent} from '../../../popup-post/popup-post.component';
import {ContactsService} from '../../../../../../shared/services/contacts/contacts.service';
import { PageEvent } from '@angular/material/paginator';
import {PostService} from '../../post.service';

@Component({
    selector: 'app-profile-photos-videos',
    templateUrl: './profile-photos-videos.component.html',
    styleUrls: ['./profile-photos-videos.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class ProfilePhotosVideosComponent implements OnInit, OnDestroy {

    photosVideos: any;
    videoEnabled = false;
    posts: any[];
    //page: { count: number; limit: number; offset: number; isDeleted: boolean; sort: any; }
    // @Input() posts: any[];
    @Input() page: any;
    @Input() dataSource: any
    @Input() loadPostData: (limit: number, count: number, offset: number, isDeleted: boolean, sort: any, postService: any, cd: any) => void;
    // Private

    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {ProfileService} _profileService
     */
    constructor(
        private _matDialog: MatDialog,
        private contactService: ContactsService,
        private postService: PostService,
        private cd: ChangeDetectorRef
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // this._profileService.photosVideosOnChanged
        //     .pipe(takeUntil(this._unsubscribeAll))
        //     .subscribe(photosVideos => {
        //         this.photosVideos = photosVideos;
        //     });
        this.contactService.onMsg.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(data => {
                if (data && data.type === 'delete') {
                    this.posts = this.posts.filter(item => {
                        if (item.id !== data.id) {
                            return item;
                        }
                    });
                }
            });

        this.dataSource.postSubject
            .pipe()
            .subscribe(response => {
                if (response != null && response['success']) {
                    this.posts = response.rows;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    onPlayingVideo($event: Event, media: HTMLVideoElement): void {
        media.pause();
    }

    openPopup(post: any): void {
        const dialogRef = this._matDialog.open(PopupPostComponent,
            {
                width: '600px',
                data: {
                    post: post
                }
            }
        );
        // dialogRef.afterClosed().subscribe(data =>{
        //     if(data.type == 'delete'){
        //         this.posts = this.posts.filter(item => {
        //             if(item.id !== data.id){
        //                 return item;
        //             }
        //         })
        //     }
        // })
    }

    async handleLoadPost(event: PageEvent) {

        const pageSize = this.page.limit;
        const count = this.page.count;
        const page = event.pageIndex;
        const isDeleted = this.page.isDeleted;
        const sort = this.page.sort;

        await this.loadPostData(pageSize, count, page, isDeleted, sort, this.postService, this.cd);

        this.dataSource.postSubject
            .pipe()
            .subscribe(response => {
                if (response != null && response['success']) {
                    this.posts = response.rows;
                }
            });
    }
}
