import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FuseSharedModule } from '@fuse/shared.module';
import {AppMaterialModule} from '../../../app-material.module';
import { BrowserModule } from '@angular/platform-browser';
import { CookieModule } from 'ngx-cookie';
import { Routes, RouterModule } from '@angular/router';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
@NgModule({
  declarations: [LoginComponent],
  imports: [
        CommonModule,
        AppMaterialModule,
        FuseSharedModule,
        BrowserModule,
        CookieModule.forRoot(),
        RouterModule,
        MatSnackBarModule
  ]
})
export class AuthModule { }
