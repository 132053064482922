import { Component } from '@angular/core';
import { CommentService } from "@shared/services/comment/comment.service";

@Component({
  selector: 'app-hide-user-comment',
  templateUrl: './hide-user-comment.component.html',
  styleUrls: ['./hide-user-comment.component.scss']
})
export class HideUserCommentComponent {
  users: any[];
  page = {
    limit: 20,
    count: 0,
    offset: 0,
    currentPage: 1,
    totalPages: 0
  };
  loadingIndicator: boolean = true;
  reorderable: boolean;

  constructor(
    private commentService: CommentService
  ) {

  }

   ngOnInit(): void {
    this.handleGetHiddenUserComment({ page: 1, limit: 20 });
  }

  handleGetHiddenUserComment(query) {
    // Call the service with the computed offset and limit
    this.commentService.getHideUserComments(query).subscribe((response: any) => {
      if (response?.success) {
        console.log(response);
        // Update pagination data based on API response
        this.page.count = response.data.pagination.total;
        this.page.limit = response.data.pagination.limit;
        this.page.currentPage = response.data.pagination.page - 1;
        this.page.totalPages = response.data.pagination.totalPages;
        this.users = response.data.users;
      }
    });
  }

   pageCallback(event: any): void {
    const currentPage = event.offset + 1;
    this.handleGetHiddenUserComment({
      page: currentPage,
      limit: this.page.limit
    });
  }

   toggleUserComment(row:any) {
    const payload = {
      hide_user_comment: false
    };
     this.commentService.toggleHideUserComment(row.user_id._id, payload).subscribe((response: any) => {
      if (response.success) {
        row.hide_user_comment = !row.hide_user_comment;
        this.handleGetHiddenUserComment({
          page: this.page.currentPage + 1,
          limit: this.page.limit
        });
      }
     })
  }
}
