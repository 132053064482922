import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { catchError, finalize, takeUntil } from 'rxjs/operators';
import { SyslogService } from '../../../../shared/services/syslog/syslog.service';
import { AppConstants } from '../../../../shared/constants';
import { FuseProgressBarService } from '../../../../../@fuse/components/progress-bar/progress-bar.service';
import { ContentHtmlComponent } from "./content-html/content-html.component"
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS, MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions } from '@angular/material/legacy-tooltip';

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchendHideDelay: 0,
};

@Component({
    selector: 'app-syslog',
    templateUrl: './syslog.component.html',
    styleUrls: ['./syslog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults }]
})
export class SyslogComponent implements OnInit {

    selected = [];
    rows: any[];
    loadingIndicator = true;
    reorderable: boolean;
    user: any = null;
    isDeleted = false;
    tags = [];
    page = {
        limit: 15,
        count: 0,
        offset: 0,
        isDeleted: false,
        sort: {}
    };
    dataSource: FilesDataSource | null;
    private _unsubscribeAll: Subject<any>;

    constructor(private fuseProgressBarService: FuseProgressBarService, private sysLogService: SyslogService, public _matDialog: MatDialog) {
        this._unsubscribeAll = new Subject();
        this.user = JSON.parse(localStorage.getItem(AppConstants.currentUser));
    }

    ngOnInit(): void {
        this.dataSource = new FilesDataSource(this.sysLogService);
        this.dataSource.loadLogData(15, 0, 0, false, {});

        this.dataSource.logSubject
            .pipe()
            .subscribe(response => {
                if (response != null && response['success']) {
                    this.rows = response.rows;
                    this.page.count = response.pageCount;
                }
            });

        this.dataSource.loadingSubject
            .pipe()
            .subscribe(loadParam => {
                if (loadParam) {
                    this.fuseProgressBarService.show();
                } else {
                    this.fuseProgressBarService.hide();
                }
            });
    }

    openModal(content: string ) {
        if (content.length <= 0) return;
        this._matDialog.open(ContentHtmlComponent, {
            data: { content }
        });
    }

    onActivate(event): void {
    }

    onSelect({ selected }): void {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    pageCallback(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }): void {
        this.page.offset = pageInfo.offset;
        this.reloadTable();
    }

    reloadTable(): void {
        this.dataSource.loadLogData(this.page.limit, this.page.count, this.page.offset, this.page.isDeleted, this.page.sort);
    }

    onSort(event): void {
        const sort = event.sorts[0];
        if (sort) {
            this.page.sort = sort;
            this.dataSource.loadLogData(15, 0, 0, this.page.isDeleted, this.page.sort);
        }
    }

}

export class FilesDataSource implements DataSource<any> {
    public loadingSubject = new BehaviorSubject<boolean>(false);
    public logSubject = new BehaviorSubject<any>(null);

    constructor(
        private syslogService: SyslogService,
    ) {
    }

    /**
     * Connect function called by the table to retrieve one stream containing the data to render.
     * @returns {Observable<any[]>}
     */
    connect(collectionViewer: CollectionViewer): Observable<any> {
        return this.logSubject.asObservable();
    }

    /**
     * Disconnect
     */
    disconnect(collectionViewer: CollectionViewer): void {
        this.logSubject.complete();
        this.loadingSubject.complete();
    }

    // tslint:disable-next-line:typedef
    loadLogData(limit: number, count: number, offset: number, isDeleted: boolean, sort: any) {
        this.loadingSubject.next(true);
        this.syslogService.getLogAll({
            limit: limit,
            count: count,
            offset: offset,
            isDeleted: isDeleted,
            sort: sort
        })
            .pipe(
                catchError(() => of()),
                finalize(() => this.loadingSubject.next(false))
            )
            .subscribe(response => {
                if (response['success'] && response['data']) {
                    this.logSubject.next({
                        success: true,
                        rows: response['data'].logList,
                        pageCount: response['data'].logCount
                    });
                } else {
                    this.logSubject.next({ success: false });
                }
            });
    }
}


