import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Report} from '../../models/report.model';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    uri = environment.apiURL + 'reports/';

    constructor(private http: HttpClient) {
    }

    getAll(): Observable<any> {
        return this.http.get<Report[]>(this.uri + 'getAll').pipe(map(res => res['data']));
    }

    updateFlag(param = {}): Observable<any> {
        return this.http.post(this.uri + 'updateFlag', param);
    }

    createReply(param = {}): Observable<any> {
        return this.http.post(this.uri + 'createReply', param);
    }

    deleteReports(param = {}): Observable<any> {
        return this.http.post(this.uri + 'deleteReports', param);
    }

    getComment(params={}): Observable<any> {
        return this.http.post(environment.apiURL + 'comment/' + 'loadCommentReportAdmin/', params);
    }

    createComment(params = {}) {
        return this.http.post(environment.apiURL + 'comment/' + 'createCommentReportAdmin/', params);
    }
}
