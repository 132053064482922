<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-black m-0">
        <mat-toolbar-row class="toolbar-bottom py-16 heightauto" fxLayout="column" fxLayoutAlign="center center">
            <img [src]="this.postData.media" class="contact-avatar huge m-0" [alt]="this.postData.title" *ngIf="this.postData.type == 2"/>
            <video (playing)="onPlayingVideo($event, media)" *ngIf="postData?.type === 3" id="singleVideo" preload="auto" controls style="max-width: 100%;max-height: 250px" class="mediaTag" #media>
                <source [src]="postData?.media" type="video/3gpp">
            </video>
            <div class="contact-name mt-8 whiteC">{{this.postData.title}}</div>
        </mat-toolbar-row>
    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="contactForm">
            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Tag</mat-label>
                    <mat-chip-list #tagList name="tags" formControlName="tags">

                        <mat-chip *ngFor="let tag of this.postData.hashTags"
                                  [removable]="true" (removed)="removeTag(tag)">
                            {{tag}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                        </mat-chip>

                        <input
                            #fruitInput
                            [formControl]="fruitCtrl"
                            [matAutocomplete]="auto"
                            [matChipInputFor]="tagList"
                            [matChipInputAddOnBlur]="true"/>

                    </mat-chip-list>
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                        <mat-option *ngFor="let fruit of tags | async" [value]="fruit">
                            {{fruit}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </form>
    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
        <button
                mat-button
                class="mr-8"
                (click)="deletePost(this.postData.id)"
                aria-label="Delete"
                matTooltip="Delete">
            DELETE
        </button>
    </div>
</div>
