import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { CookieService } from 'ngx-cookie';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { AuthService } from '../../../../shared/services/auth/auth.service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    loginFormErrors: any;

    private loggedIn: boolean;
    submitted = false;
    loading = false;
    errorOccurred = false;
    error = 'Unknown Error';
    returnUrl: string;
    siteName: string;
   constructor(
       private fuseConfig: FuseConfigService,
       private _matSnackBar: MatSnackBar,
       private _cookieService: CookieService,
       private formBuilder: UntypedFormBuilder,
       private router: Router,
       private route: ActivatedRoute,
       private userAuthService: AuthService,
   ) {

       this.fuseConfig.setConfig({
           layout: {
               navbar: {
                   hidden: true
               },
               toolbar: {
                   hidden: true
               },
               footer: {
                   hidden: true
               },
           }
       });
       this.loginFormErrors = {
           loginId: {},
           password: {}
       };
       this.siteName = environment.sitename;
   }

   ngOnInit(): void {
       this.loginForm = this.formBuilder.group({
           loginId: ['', [Validators.required]],
           password: ['', Validators.required],
           rememberCheckbox: ['']
       });
       this.loginForm.valueChanges.subscribe(() => {
           this.onLoginFormValuesChanged();
       });

       // get return url from route parameters or default to '/'
       this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
   }

    onLoginFormValuesChanged() {
        for (const field in this.loginFormErrors) {
            if (!this.loginFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.loginFormErrors[field] = {};

            // Get the control
            const control = this.loginForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.loginFormErrors[field] = control.errors;
            }
        }
    }

    get f() { return this.loginForm.controls; }

    onSubmit() {
        this.submitted = true;
        this.loading = true;
        this.errorOccurred = false;
        this.error = 'Unknown Error';
        this.userAuthService.signIn(this.f.loginId.value, this.f.password.value, this.f.rememberCheckbox.value).pipe(first()).subscribe((data) => {
            this.loading = false;
            this.userAuthService.setLoggedIn(true);
            if (data.success && data.success > 0) {
                location.href = this.returnUrl;
            } else {
                this.showNotification(data.error);
            }
        }, (error) => {
            this.loading = false;
            if (error instanceof HttpErrorResponse) {
                this.showNotification( error.error.error);
            } else {
                this.showNotification( error);
            }
        });
    }

    showNotification(msg) {
        this._matSnackBar.open(msg, '', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            politeness : 'assertive',
            duration        : 3000
        });
    }

}
