import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Comment} from '../../models/comment.model';
import {environment} from 'environments/environment';

interface CommentResponse {
  data: {
    comments: Comment[];
    pagination: {
      total: number;
      page: number;
      limit: number;
      totalPages: number;
    };
  };
  error: any;
  success: any;
}

@Injectable({
    providedIn: 'root'
})
export class CommentService {
    uri = environment.apiURL + 'comment/';

    constructor(private http: HttpClient) {
    }

     getAll(query?: {page: number, limit: number}): Observable<CommentResponse> {
      return this.http.get<CommentResponse>(this.uri, {
        params: query ? { page: query.page, limit: query.limit } : {},
      });
    }

    hideComment(id: string, payload: {hide: boolean}) {
      return this.http.patch(this.uri + id, payload);
    }

    toggleHideUserComment(id: string, payload: {hide_user_comment: boolean}) {
      return this.http.patch(this.uri + 'user-comments/' + id, payload);
    }

    getHideUserComments(query?: {page: number, limit: number}): Observable<any> {
      return this.http.get<any>(this.uri + 'c/hide-user-comments', {
        params: query ? { page: query.page, limit: query.limit } : {},
      });
    }
}
