<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{dialogTitle}}</span>
            <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>

    </mat-toolbar>

    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

        <form [formGroup]="contactForm">

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Name</mat-label>
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <input name="name" formControlName="name" matInput required>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="start start">
                <mat-form-field appearance="outline" fxFlex>
                    <mat-label>Email</mat-label>
                    <mat-icon matSuffix class="secondary-text">email</mat-icon>
                    <input name="email" formControlName="email" matInput type="email">
                </mat-form-field>
            </div>
        </form>

    </div>

    <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

        <button mat-button class="mr-8" (click)="resetPassword()" aria-label="Reset Password" matTooltip="Reset Password">
            {{ isLoading ? 'LOADING...' : 'RESET PASSWORD' }}
        </button>

        <button *ngIf="action === 'edit'"
                mat-button
                class="mr-8"
                (click)="matDialogRef.close(['cancel',contactForm])"
                aria-label="Cancel"
                matTooltip="Cancel">
            CANCEL
        </button>

        <button *ngIf="action !== 'edit'"
                mat-button
                class="save-button"
                (click)="matDialogRef.close(contactForm)"
                [disabled]="contactForm.invalid"
                aria-label="SAVE">
            ADD
        </button>

        <button *ngIf="action === 'edit'"
                mat-button
                class="save-button"
                (click)="save()"
                [disabled]="contactForm.invalid"
                aria-label="SAVE">
            SAVE
        </button>

    </div>
</div>
