import {Component, Inject, OnInit, ViewEncapsulation, OnDestroy, ElementRef, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {Contact} from "../contacts/contact.model";
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";
import {ContactsService} from "../../../../shared/services/contacts/contacts.service";
import {map, startWith, takeUntil} from "rxjs/operators";
import {Observable, Subject} from "rxjs";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent} from "@angular/material/legacy-autocomplete";

@Component({
    selector: 'app-popup-post',
    templateUrl: './popup-post.component.html',
    styleUrls: ['./popup-post.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class PopupPostComponent implements OnDestroy, OnInit {
    separatorKeysCodes: number[] = [ENTER, COMMA];
    action: string;
    contact: Contact;
    contactForm: UntypedFormGroup;
    dialogTitle: string;
    postData: any;
    tags: Observable<string[]>;
    fruitCtrl = new UntypedFormControl('');
    private _unsubscribeAll: Subject<any>;
    @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement>;

    /**
     * Constructor
     *
     * @param {MatDialogRef<ContactsContactFormDialogComponent>} matDialogRef
     * @param _data
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        public matDialogRef: MatDialogRef<PopupPostComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: UntypedFormBuilder,
        private contactService: ContactsService
    ) {
        this.dialogTitle = 'Edit Post';
        this.contactForm = this.createContactForm();
        this.postData = this.data.post;
        this._unsubscribeAll = new Subject();
        this.tags = this.fruitCtrl.valueChanges.pipe(
            startWith(null),
            map((fruit: string | null) => (fruit ? this._filter(fruit) : this.data.allTags.slice())),
        );
    }

    ngOnInit() {

    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Create contact form
     *
     * @returns {FormGroup}
     */
    createContactForm(): UntypedFormGroup {
        return this._formBuilder.group({
            tags: []
        });
    }

    removeTag(tag: any): void {
        if (this?.postData?.hashTags) {
            this.contactService.modifyTag(this.postData.id, tag, 'delete')
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe(response => {
                    for (const i in this.postData.hashTags) {
                        if (this.postData.hashTags[i] === tag) {
                            this.postData.hashTags.splice(i, 1);
                        }
                    }
                });

        }
    }

    addTag(value) {

        // Add tag
        if (value) {
            if (!this.postData.hashTags.includes(value)) {
                this.contactService.modifyTag(this.postData.id, value, 'add')
                    .pipe(takeUntil(this._unsubscribeAll))
                    .subscribe(response => {
                        if (response['success'] === 1) {
                            this.postData.hashTags.push(value);
                        }
                    });

            }
        }
    }

    deletePost(id: any) {
        this.contactService.deletePosts({ids: id})
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((data) => {
                this.contactService.setOnMsg({id: id, type: 'delete'});
                this.matDialogRef.close({id: id, type: 'delete'});
            });
    }

    onPlayingVideo($event: Event, media: HTMLVideoElement) {

    }

    selected(event: MatAutocompleteSelectedEvent): void {
        this.addTag(event.option.viewValue)
        this.fruitInput.nativeElement.value = '';
        this.fruitCtrl.setValue(null);
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();

        return this.data.allTags.filter(fruit => fruit.toLowerCase().includes(filterValue));
    }
}
