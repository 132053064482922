import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplashScreenService {
  private visibilitySubject = new BehaviorSubject<boolean>(true);
  visibility$ = this.visibilitySubject.asObservable();

  show(): void {
    this.visibilitySubject.next(true);
  }

  hide(): void {
    this.visibilitySubject.next(false);
  }
}