<div id="forms" class="page-layout simple fullwidth" fxLayout="column">
    <div class="header accent p-24 h-160" fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="column" fxLayoutAlign="center start">
            <div class="h1 mt-16">Settings</div>
        </div>
    </div>
    <div class="content p-24">
        <div class="mb-24" fxLayout="column" fxLayoutAlign="start" fxLayout.gt-md="row">
            <form class="mat-card mat-elevation-z4 p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
                  fxFlex="1 0 auto" name="form" [formGroup]="form">
                <div fxLayout='row' fxLayoutAlign="start center" fxFlex="1 0 auto">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>CurrenyName</mat-label>
                        <input matInput formControlName="currency">
                        <mat-icon matSuffix>attach_money</mat-icon>
                    </mat-form-field>
                </div>
                <div class="section pb-48">

                    <div class="pb-16" fxLayout="row" fxLayoutAlign="start center">
                        <mat-icon class="m-0 mr-16 secondary-text">aspect_ratio</mat-icon>
                        <div class="h2 secondary-text">Post Ratio</div>
                    </div>

                    <table class="simple">
                        <thead>
                        <tr>
                            <th>Under</th>
                            <th>Updated On</th>
                        </tr>
                        </thead>
                        <tbody>

                        <tr *ngFor="let status of rationPosts">
                            <td>
                                <span class="status h6 p-4" [ngClass]="status.color">
                                    {{ status.name }}
                                </span>
                            </td>
                            <td>
                                <span>
                                    {{ status.date | date }}
                                </span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <button mat-raised-button type="button" color="accent" (click)="submit()">
                        Submit
                    </button>
                </div>
            </form>

        </div>
    </div>
</div>