import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {CookieService} from 'ngx-cookie';
import 'rxjs/add/operator/map';
import {AppConstants} from '../../constants';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs/Observable';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    uri = 'auth';

    constructor(
        private http: HttpClient,
        private _cookieService: CookieService
    ) {
        const cookie = this.getCookie(AppConstants.currentUser);
        const sess = sessionStorage.getItem(AppConstants.currentUser);
        if (cookie) {
            const cookieObj = JSON.parse(cookie);
            if (cookieObj && cookieObj.username && cookieObj.token && cookieObj.user) {
                this.updateLocalStorage(cookieObj.user);
                this.loggedIn.next(true);
            }
        } else if (sess) {
            // tslint:disable-next-line:prefer-const
            let sessObj = JSON.parse(sess);
            if (sessObj && sessObj.username && sessObj.token && sessObj.user) {
                this.updateLocalStorage(sessObj.user);
                this.loggedIn.next(true);
            }
        }
    }

    get isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    public setLoggedIn(bool): void {
        this.loggedIn.next(bool);
    }

    getCookie(key: string): string {
        return this._cookieService.get(key);
    }

    updateLocalStorage(user: any): void {
        localStorage.setItem(AppConstants.currentUser, JSON.stringify(user));
    }

    public getCurrentUser(): string {
        const cookie = this.getCookie(AppConstants.currentUser);
        if (cookie) {
            const cookieObj = JSON.parse(cookie);
            console.log('cookieObj: ', cookieObj);
            if (cookieObj && cookieObj.username && cookieObj.token) {
                console.log('returned cookie obj', cookieObj);
                return cookie;
            }
        } else {
            const sess = sessionStorage.getItem(AppConstants.currentUser);
            if (sess) {

                const sessObj = JSON.parse(sess);
                console.log('sess: ', sessObj);

                if (sessObj && sessObj.username && sessObj.token) {
                    console.log('returned sess obj', sessObj);
                    return sess;
                }
            }
            console.log('current user is null');
            return null;
        }
    }

    public setCurrentUer(user: any): void {
        const cookie = this.getCookie(AppConstants.currentUser);
        let shouldSend = false;
        if (cookie) {
            const cookieObj = JSON.parse(cookie);
            if (cookieObj && cookieObj.username && cookieObj.token && cookieObj.user) {
                cookieObj.user = user;
                cookieObj.user['token'] = cookieObj.token;

                console.log('setCurrentUser: cookie: ', cookieObj);

                this.updateLocalStorage(cookieObj.user);
                this.setCookie(AppConstants.currentUser, cookieObj);
                shouldSend = true;
            }
        }

        const sess = sessionStorage.getItem(AppConstants.currentUser);
        if (sess) {
            const sessObj = JSON.parse(sess);
            if (sessObj && sessObj.username && sessObj.token && sessObj.user) {
                sessObj.user = user;
                sessObj.user['token'] = sessObj.token;

                console.log('setCurrentUser: sess: ', sessObj);

                this.updateLocalStorage(sessObj.user);

                sessionStorage.setItem(AppConstants.currentUser, JSON.stringify(sessObj));
                shouldSend = true;
            }
        }

        if (shouldSend) {
            this.loggedIn.next(true);
        }

    }

    private removeCookie(key: string): void {
        this._cookieService.remove(key);
    }

    private setCookie(key: string, value: any, expirationDate?: Date): void {
        const expDate = new Date();
        expDate.setDate(expDate.getDate() + 1);
        this._cookieService.putObject(key, value, {expires: expDate});
    }

    public logout(): void {
        this.loggedIn.next(false);
        // remove user from local storage to log user out
        this._cookieService.remove(AppConstants.currentUser);
        sessionStorage.removeItem(AppConstants.currentUser);
        localStorage.removeItem(AppConstants.currentUser);
    }

    public signIn(loginId: string, password: string, isRemember?: boolean): any {
        let params = {};
        params = {
            loginId: loginId,
            password: password,
        };
        const url = environment.apiURL + this.uri + '/login_admin';
        return this.postData(url, params, 'login', isRemember);
    }

    // tslint:disable-next-line:typedef
    private postData(url, params, funcName?: string, isRemember?: boolean) {
        return this.http.post<any>(url, params).map(data => {
            if (data && data.success && data.success === 1) {
                if ( /*funcName === 'login' && */ data.user && data.user.token) {
                    // this.loggedIn.next(true);
                    // store user details and jwt token in the local storage to keep user logged in between page refreshes
                    if (isRemember) {
                        this.setCookie(AppConstants.currentUser, {
                            username: data.user.username,
                            token: data.user.token
                        });
                    } else {
                        this.removeCookie(AppConstants.currentUser);
                    }
                    sessionStorage.setItem(AppConstants.currentUser, JSON.stringify({
                        username: data.user.username,
                        token: data.user.token
                    }));
                    this.updateLocalStorage(data.user);
                    this.setLoggedIn(true);
                }
            }

            return data;
        });
    }
}
