<div id="ngx-datatable" class="page-layout simple fullwidth docs">

    <!-- HEADER -->
    <div class="header accent p-24 h-160" fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="row"
         fxLayoutAlign.gt-xs="space-between center">
        <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-xs="column" fxLayoutAlign.gt-xs="center start">
            <div class="h2 mt-16">Tags</div>
        </div>
        <button mat-raised-button
                (click)="addTag()"
                class="add-product-button fuse-white mt-24 mt-md-0">
            <span>ADD NEW TAG</span>
        </button>
    </div>
    <!-- / HEADER -->

    <!-- CONTENT -->
    <div class="content p-24">

        <ngx-datatable
            class="material"
            [selected]="selected"
            (select)='onSelect($event)'
            (activate)="onActivate($event)"
            [rows]="rows"
            [loadingIndicator]="loadingIndicator"
            [columnMode]="'force'"
            [headerHeight]="48"
            [footerHeight]="56"
            [rowHeight]="'auto'"
            [scrollbarH]="true"
            [reorderable]="reorderable"
            [selectionType]="'checkbox'"
            [limit]="15">

            <ngx-datatable-column [width]="48"
                                  [canAutoResize]="false"
                                  [sortable]="false">
                <ng-template ngx-datatable-header-temp
                             late let-value="value" let-allRowsSelected="allRowsSelected"
                             let-selectFn="selectFn">
                    <mat-checkbox [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)"></mat-checkbox>
                </ng-template>

                <ng-template ngx-datatable-cell-template let-value="value" let-isSelected="isSelected"
                             let-onCheckboxChangeFn="onCheckboxChangeFn">
                    <mat-checkbox [checked]="isSelected" (change)="onCheckboxChangeFn($event)"></mat-checkbox>
                </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="Tag" prop="content" ></ngx-datatable-column>
        </ngx-datatable>
        <button mat-raised-button type="button" color="warn" class="mt-8 mr-8" (click)="clear()">
            Clear
        </button>
        <button mat-raised-button type="button" color="warn" class="mt-8" (click)="clear('all')">
            Clear All
        </button>
    </div>
</div>

