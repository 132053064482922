import {Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {ContactsService} from '../../../../shared/services/contacts/contacts.service';
import {fromEvent, Subject} from 'rxjs';
import {AppConstants} from '../../../../shared/constants';
import {MatDialog} from '@angular/material/dialog';
import {PayInformComponent} from './pay-inform/pay-inform.component';
import {debounceTime, distinctUntilChanged, takeUntil} from "rxjs/operators";
import {SocketService} from 'app/shared/services/socket.service';

@Component({
    selector: 'app-payout',
    templateUrl: './payout.component.html',
    styleUrls: ['./payout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PayoutComponent implements OnInit, OnDestroy {

    rows: any[];
    loadingIndicator: boolean;
    reorderable: boolean;
    user: any = null;
    selected = [];
    page = {
        limit: 15,
        count: 0,
        offset: 0,
        isFlagged: false
    };
    searchParam = '';

    @ViewChild('filter', {static: true})
    filter: ElementRef;

    private _unsubscribeAll: Subject<any>;

    constructor(
        private contactsService: ContactsService,
        private _matDialog: MatDialog,
        private socketService: SocketService,
    ) {
        this._unsubscribeAll = new Subject();
        this.user = JSON.parse(localStorage.getItem(AppConstants.currentUser));
    }

    ngOnInit(): void {
        this.pageCallback({offset: 0});
        if (this.user != null && this.user.id) {
            this.socketService.emitData('connecting', this.user.id);
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    onSelect({selected}) {
        this.selected.splice(0, this.selected.length);
        this.selected.push(...selected);
    }

    gotoPayInform(row) {
        if (!row) {
            return;
        }

        const dialogRef = this._matDialog.open(PayInformComponent,
            {
                width: '800px', data: row
            }
        ).afterClosed()
            .subscribe(response => {
                if (response && response.success && response.result) {
                    const updatedResult = response.result;
                    this.rows = this.rows.filter(payItem => {
                        if (payItem.id === updatedResult.id) {
                            payItem.isFlagged = updatedResult.isFlagged;
                            payItem.firstName = updatedResult.firstName;
                            payItem.lastName = updatedResult.lastName;
                            payItem.address = updatedResult.address;
                            payItem.city = updatedResult.city;
                            payItem.state = updatedResult.state;
                            payItem.postalCode = updatedResult.postalCode;
                            payItem.country = updatedResult.country;
                            payItem.sendTo = updatedResult.sendTo;
                            payItem.transactionID = updatedResult.transactionID;
                            payItem.fromCash = updatedResult.fromCash;
                            payItem.toCash = updatedResult.toCash;
                            payItem.payment = updatedResult.payment;
                        }
                        return payItem;
                    });
                }
            });

    }

    deletePayInform() {

    }

    pageCallback(pageInfo: {
        count?: number,
        pageSize?: number,
        limit?: number,
        offset?: number,
        isFlagged?: boolean
    }) {
        this.page.offset = pageInfo.offset;
        this.reloadTable();
    }

    reloadTable() {
        this.contactsService.getPayouts(this.page).subscribe((data) => {
            this.rows = data.payOutList;
            if (data.payOutCount >= 0) {
                this.page.count = data.payOutCount;
            }
            this.page.isFlagged = true;
        }, (err) => {
            console.log(err);
        });
    }
}
