import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';

import {Color, Label} from 'ng2-charts';
import {ChartDataSets, ChartOptions} from 'chart.js';
import {AnalyticsService} from '../../../../shared/services/analytics/analytics.service';
import * as moment from 'moment';
import {TimePeriod} from 'ngx-daterangepicker-material/daterangepicker.component';


@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class AnalyticsComponent implements OnInit {

    /**
     * Constructor
     *
     * @param {AnalyticsDashboardService} _analyticsDashboardService
     */
    constructor(
        private analyticsService: AnalyticsService
    ) {
        // Register the custom chart.js plugin
        // this._registerCustomChartJSPlugin();
    }

    widgets: any;
    widget5SelectedDay = 7;
    widgetsType = 'new';
    widgetLabel = 'New Member Signups';
    selected = null;
    movingAverage = 1;
    invalidDates: moment.Moment[] = [moment().add(2, 'days'), moment().add(3, 'days'), moment().add(5, 'days')];

    lineChartData: ChartDataSets[] = [];
    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    };
    lineChartLabels: Label[] = [];
    lineChartOptions: ChartOptions & { annotation: any } = {
        annotation: undefined,
        responsive: true,
        scales: {
            yAxes: [
                {
                    gridLines: {
                        tickMarkLength: 16,
                    },
                    ticks: {
                        stepSize: 10,
                        beginAtZero: true,
                    },
                    display: true,
                },
            ],
            xAxes: [
                {
                    ticks: {
                        callback: (val, index) => {
                            // Hide every 2nd tick label
                            const splitOfStr = val.toString().split(' ');
                            return splitOfStr[0] + ' ' + splitOfStr[1];
                        },
                    },
                    display: true,
                }
            ]
        },
        elements: {
            line: {
                // tension: 0,
                fill: true,
            }
        },
        maintainAspectRatio: false
    };
    rangeSelected: any;

    public lineChartColors: Color[] = [
        {

            pointBackgroundColor: 'rgb(0, 143, 251)',
            borderWidth: 1,
            pointHoverRadius: 5,
            pointStyle: ''
        },
        {

            pointBackgroundColor: 'rgb(0, 227, 15)',
            borderWidth: 1,
            pointHoverRadius: 5,
        },
        {

            pointBackgroundColor: 'rgb(255, 0, 0)',
            borderWidth: 1,
            pointHoverRadius: 5,
        },
    ];

    private backgroundColor = [
        {backgroundColor: 'rgba(0, 143, 251,0.3)', borderColor: 'rgb(0, 143, 251)', borderAverageColor: '#000'},
        {backgroundColor: 'rgba(0, 227, 15,0.3)', borderColor: 'rgb(0, 227, 150)', borderAverageColor: '#f900ff'},
        {backgroundColor: 'rgba(255, 0, 0,0.3)', borderColor: 'rgb(255, 0, 0)', borderAverageColor: '#b95454'}
    ];
    public lineChartLegend = true;
    public lineChartType = 'line';
    public lineChartPlugins = [];

    isInvalidDate = (m: moment.Moment) => {
        return this.invalidDates.some(d => d.isSame(m, 'day'));
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.analyticsService.getAnalytics(
            {
                type: this.widgetsType,
                lastDays: this.widget5SelectedDay,
                firstDate: '',
                lastDate: '',
                moving: this.movingAverage
            }
        );
        this.analyticsService.onDatasUpdated.subscribe(response => {
            if (response.data) {
                const tempChartData = new Array();
                response.data.forEach((currentItem, index) => {
                    tempChartData.push({
                        data: response.data[index],
                        label: response.label[index],
                        pointRadius: 3,
                        backgroundColor: this.backgroundColor[index].backgroundColor,
                        borderColor: this.backgroundColor[index].borderColor
                    });
                });
                response.movingAvg.forEach((currentItem, index) => {
                    tempChartData.push({
                        data: response.movingAvg[index],
                        label: response.labelAvg[index],
                        pointRadius: 0,
                        borderWidth: 1,
                        backgroundColor: 'transparent',
                        borderColor: this.backgroundColor[index].borderAverageColor
                    });
                });
                this.lineChartData = tempChartData;
                this.lineChartLabels = response.labels;
            }
        });
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------


    /**
     * Register a custom plugin
     */
    private _registerCustomChartJSPlugin(): void {
        (window as any).Chart.plugins.register({
            id: 'customCanvasBackgroundColor',
            beforeDraw: (chart, args, options) => {
                const {ctx} = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = options.color || '#000';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            }
        });
    }

    selectLastDate(days: number): void {
        this.widget5SelectedDay = days;
        this.analyticsService.getAnalytics({
            type: this.widgetsType,
            lastDays: days,
            firstDate: '',
            lastDate: '',
            moving: this.movingAverage
        });
        this.selected = null;
    }

    datesUpdated($event: TimePeriod): void {
        if ($event.startDate) {
            this.widget5SelectedDay = -1;
            this.analyticsService.getAnalytics({
                lastDays: -1,
                type: this.widgetsType,
                firstDate: $event.startDate.format('YYYY-MM-DD'),
                lastDate: $event.endDate.format('YYYY-MM-DD'),
                moving: this.movingAverage
            });
        }
    }

    selectWidgetType(widgetType: string): void {
        let firstDate = '';
        let lastDate = '';
        if (this.selected && this.selected.startDate) {
            firstDate = this.selected.startDate.format('YYYY-MM-DD');
            lastDate = this.selected.endDate.format('YYYY-MM-DD');
        }

        this.widgetsType = widgetType;
        this.analyticsService.getAnalytics({
            type: this.widgetsType,
            lastDays: this.widget5SelectedDay,
            firstDate: firstDate,
            lastDate: lastDate,
            moving: this.movingAverage
        });
        switch (widgetType) {
            case 'new':
                this.widgetLabel = 'New Member Signups';
                break;
            case 'login':
                this.widgetLabel = 'Logins Per Day';
                break;
            case 'post':
                this.widgetLabel = 'Posts Per Day';
                break;
            case 'token':
                this.widgetLabel = 'Spent Tokens Per Day';
                break;
            case 'message':
                this.widgetLabel = 'Messages Per Day';
                break;
        }
    }

    selectMovingAverage(value: any): void {
        this.movingAverage = value;
        let firstDate = '';
        let lastDate = '';
        if (this.selected && this.selected.startDate) {
            firstDate = this.selected.startDate.format('YYYY-MM-DD');
            lastDate = this.selected.endDate.format('YYYY-MM-DD');
        }

        this.analyticsService.getAnalytics({
            type: this.widgetsType,
            lastDays: this.widget5SelectedDay,
            firstDate: firstDate,
            lastDate: lastDate,
            moving: this.movingAverage
        });
    }
}

